<template>
  <li :class="bem('')">
    <LinkCheck :url="url" :class="bem('wrapper')">
      <Icon v-if="check" icon="bullet/check" :class="bem('check', [check, iconSize])" inline color="current" />
      <Icon v-else-if="icon" :icon="icon" :class="bem('icon', iconSize)" />
      <span v-else-if="!!$slots.icon" :class="bem('icon', iconSize)">
        <slot name="icon" />
      </span>
      <div :class="bem('content')">
        <div v-if="label" :class="bem('label')">{{ label }}</div>
        <span v-if="!!$slots.default" :class="bem('body')">
          <slot></slot>
        </span>
      </div>
    </LinkCheck>
  </li>
</template>
<script>
import LinkCheck from '@/components/link-check'
import Icon from '@predicthq/vue3.components.icon'

export default {
  name: 'ListItem',
  components: { Icon, LinkCheck },
  props: {
    label: String,
    url: String,
    check: {
      type: String,
      validator: (value) => ['green', 'purple'].indexOf(value) !== -1,
    },
    iconSize: {
      type: String,
      default: 'small',
      validator: (size) => ['small', 'normal', 'large', 'x-large'].includes(size),
    },
    icon: String,
  },
}
</script>
<style lang="scss" scoped src="./list-item.scss" />
