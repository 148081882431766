<template>
  <SideLayout :class="bem('')" v-bind="content.aside">
    <div :class="bem('content')">
      <div>
        <AppTypography :class="bem('title')" :variant="titleSize">
          {{ isNewDesign ? 'Upcoming demand trends, at your fingertips.' : content.title }}
        </AppTypography>
        <div class="v-space-mt-3">
          <SignupForm :content="content" />
        </div>
        <template v-if="!isBusinessCampaign">
          <p :class="[bem('or'), 'text-p-sm']">– OR sign up with –</p>
          <AppButtonGroup>
            <AppButton
              v-for="(provider, key) in authProviders"
              :key="`btn-${key}`"
              :inline="false"
              :url="`${config.AUTH_ENDPOINT}/signup/${key}?continue=${config.APP_URL}/openid`"
              modifier="plain"
            >
              <template #startIcon>
                <AppIcon :icon="`social/${key}`" class="c-brand-purple" color="current" inline />
              </template>
              <span class="hidden-m">{{ provider }}</span>
            </AppButton>
          </AppButtonGroup>
          <AppTypography class="text-center v-space-mt-3" variant="p-sm">
            Already have an account?
            <a :href="loginUrl">Login here</a>
          </AppTypography>
        </template>
      </div>
      <AppTypography :class="bem('terms')" variant="p-sm">
        By signing up, you agree to PredictHQ's
        <a href="https://www.predicthq.com/legal/terms" target="_blank">Terms&nbsp;of&nbsp;Service</a>
        and
        <a href="https://www.predicthq.com/legal/privacy" target="_blank">Privacy&nbsp;Policy</a>.
      </AppTypography>
    </div>
  </SideLayout>
</template>

<script setup>
import { AppButtonGroup, AppButton, SignupForm } from '@/components'
import { computed, ref } from 'vue'
import { getGclid } from '@/utils/captureGclid.js'
import { SideLayout } from '@/layouts'
import { storeContinueUrl, removeContinueUrl } from '@/utils/continueUrl'
import { useRoute } from 'vue-router'
import AppIcon from '@predicthq/vue3.components.icon'
import AppTypography from '@predicthq/vue3.components.typography'
import authProviders from '@/data/auth-providers'
import config from '@/app/config'
import contentMap from '@/data/content'
import useExperiment from '@/use/experiment'

defineOptions({
  name: 'SignupView',
})

const route = useRoute()

// Start experiment
const experiment = useExperiment('phq_1169')
if (!route.query.target && !route.query.cypress) experiment.startExperiment()

const content = contentMap[route.query.target] || contentMap.default

const loginUrl = ref(config.APP_CONTROL_CENTER_URL)

const isNewDesign = computed(() => experiment.variant.value === '1')
const titleSize = computed(() => (content.title.length > 20 ? '2xl' : '3xl'))

const query = route.query

const hasGclid = !!getGclid()

// On paid campaigns, like Adwords, we'll use this flag in order to
// enforce user to enter a business email
const isBusinessCampaign = 'phq1' in route.query || hasGclid
if (isBusinessCampaign) content.businessEmail = true

// Deletes from storage in case user is revisiting the page
removeContinueUrl()

// Saves continue url to storage so we can access it from other pages
if (query.continue || content.continueUrl) {
  const continuePath = query.continue ? `/${query.continue.replace(/^\/+/g, '')}` : content.continueUrl
  storeContinueUrl(continuePath)
  loginUrl.value += continuePath
}
</script>

<style scoped lang="scss">
.signup-view {
  &__content {
    display: flex;
    flex-direction: column;
    gap: 24px;
    justify-content: space-between;
    min-height: calc(100vh - 200px);
  }
  &__chip-trial {
    margin-bottom: 16px;
  }
  &__subtext {
    color: $c-mid;
    margin-top: 12px;
  }
  &__or {
    color: $c-light;
    margin: 24px 0;
    text-align: center;
  }
  &__terms {
    color: $c-mid;
    @media (max-width: $br-800 - 1) {
      text-align: center;
    }
  }
}
</style>
