import { ref } from 'vue'
import storage from '@/utils/storage'
import heap from '@/utils/heap'

// In most cases we'll reuse the same experiment, so it makes sense
// to default to a specific experiment, but allow overriding it
const CURRENT_EXPERIMENT = 'phq_1169'

export default function useExperiment(experimentId, settings = { weight: 0.5 }) {
  const id = experimentId ?? CURRENT_EXPERIMENT
  const key = `phq.experiment.${id}`

  const variant = ref(storage.get(key, '0'))

  const hasStarted = ref(storage.has(key))

  function startExperiment() {
    // If experiment has been previously started, use value from local storage
    // otherwise, generate a random variant
    variant.value = storage.get(key, generateVariant(settings.weight))

    // We can use a query param to force showing a specific variant.
    // These users will be excluded from the experiment, as they are only
    // meant for testing purposes. e.g.: ?exp_phq_6348=1
    const query = new URLSearchParams(window.location.search)
    if (['0', '1'].includes(query.get(`exp_${id}`))) variant.value = query.get(`exp_${id}`)
    else heap('addUserProperties', { [`exp_signup_${id}`]: variant.value })

    // Store locally so it's persistent if they refresh page
    storage.set(key, variant.value)
    hasStarted.value = true
  }

  return {
    id,
    hasStarted,
    startExperiment,
    variant,
  }
}

function generateVariant(weight) {
  return Math.random() < weight ? '0' : '1'
}
