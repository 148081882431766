<template>
  <div :class="bem('', align)">
    <AppTypography v-if="!showHint" variant="p-sm" :class="bem('label')" @click="toggle">
      {{ label }} <AppIcon icon="bullet/info" :size="18" />
    </AppTypography>
    <div v-if="showHint" :class="bem('hint')">
      <button :class="bem('close')" type="button" @click="hide"><AppIcon icon="nav/close" /></button>
      <slot />
    </div>
  </div>
</template>

<script>
import AppIcon from '@predicthq/vue3.components.icon'
import AppTypography from '@predicthq/vue3.components.typography'

export default {
  name: 'AppHint',
  components: {
    AppIcon,
    AppTypography,
  },
  props: {
    align: {
      type: String,
      default: 'right',
    },
    showDefault: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
  },
  emits: ['hide'],
  data() {
    return {
      showHint: this.showDefault,
    }
  },
  methods: {
    show() {
      this.showHint = true
    },
    hide() {
      this.showHint = false
      this.$emit('hide')
    },
    toggle() {
      this.showHint = !this.showHint
    },
  },
}
</script>

<style lang="scss" scoped src="./app-hint.scss" />
