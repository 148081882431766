<template>
  <div :class="bem('', modifiers)">
    <slot />
  </div>
</template>
<script>
import './form-cell.scss'

export default {
  name: 'FormCell',
  props: {
    modifiers: Array,
  },
}
</script>
