<template>
  <template v-if="error === null">
    <SystemMessages />
    <RouterView />
  </template>
  <template v-else>
    <ErrorView :error="error" />
  </template>
</template>

<script>
import dom from '@/app/dom'
import { NotFoundError } from '@/app/error'
import config from '@/app/config.js'
import { ErrorView } from '@/views'
import SystemMessages from '@/components/shared/system-messages'

export default {
  components: {
    ErrorView,
    SystemMessages,
  },
  data() {
    return {
      classes: [],
      error: null,
    }
  },
  watch: {
    $route: 'routeChanged',
    classes(newValue) {
      dom.setBodyClasses(newValue.join(' '))
    },
  },
  created() {
    this.routeChanged()
    if (config.HEAP_ENABLED) this.loadHeapAnalyticsScript()
  },
  errorCaptured(err) {
    this.error = err

    console.error(err)

    if (err instanceof NotFoundError) {
      this.classes = ['supplemental', 'error404']
    } else {
      this.classes = ['supplemental', 'error500']
    }

    // Don't propagate
    return false
  },
  methods: {
    loadHeapAnalyticsScript() {
      const scriptTag = document.createElement('script')
      const heapScript =
        document.createTextNode(`window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=t.forceSSL||"https:"===document.location.protocol,a=document.createElement("script");a.type="text/javascript",a.async=!0,a.src=(r?"https:":"http:")+"//cdn.heapanalytics.com/js/heap-"+e+".js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(a,n);for(var o=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],c=0;c<p.length;c++)heap[p[c]]=o(p[c])};
      heap.load(${config.HEAP_APP_ID});`)
      scriptTag.appendChild(heapScript)
      document.getElementsByTagName('head')[0].appendChild(scriptTag)
    },
    routeChanged() {
      // Clear any existing error
      this.error = null

      let classes = ['supplemental']

      if (this.$route.name === '404') {
        classes = ['supplemental', 'error404']
      }

      this.classes = classes
    },
  },
}
</script>
