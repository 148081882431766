<template>
  <span :class="bem('', { center })">
    <span></span>
    <span></span>
    <span></span>
  </span>
</template>

<script>
import './app-spinner.scss'

export default {
  name: 'AppSpinner',
  props: {
    center: {
      type: Boolean
    }
  }
}
</script>
