// Used to track information into Google Analytics.
// More info: https://developers.google.com/tag-platform/tag-manager/web/datalayer

import config from '@/app/config'

window.dataLayer = window.dataLayer || []
export default function gtag() {
  window.dataLayer.push(arguments)
}
gtag('js', new Date())

gtag('config', config.GTM_TRACKING_ID)
