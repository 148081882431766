<template>
  <CenteredLayout :textCentered="true">
    <FormHero title="Well, we didn’t predict that...">
      <p v-if="error && errorType === 500">{{ error.message }}</p>
      <p v-else>
        The page you're trying to access doesn't appear to exist.
      </p>
    </FormHero>

    <AppButton url="/">Back home</AppButton>
  </CenteredLayout>
</template>

<script>
// Layouts
import { CenteredLayout } from '../layouts'
import { NotFoundError } from '@/app/error'
import { FormHero, AppButton } from '@/components'

export default {
  name: 'ErrorView',
  components: {
    FormHero,
    AppButton,
    CenteredLayout
  },
  props: {
    error: {
      type: Error,
      required: false
    }
  },
  computed: {
    errorType () {
      let type = 404

      if (this.error instanceof NotFoundError) {
        // Type remains as 404
        type = 404
      } else if (this.error instanceof Error) {
        type = 500
      }

      return type
    }
  }
}
</script>
