<template>
  <CenteredLayout>
    <template v-if="token">
      <AppTypography variant="3xl" class="v-space-mb-3">Create your account</AppTypography>
      <FormAccount v-bind="{ invite }" />
    </template>
  </CenteredLayout>
</template>

<script setup>
import { FormAccount } from '@/components'
import { CenteredLayout } from '@/layouts'
import { computed, onBeforeMount } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import AppTypography from '@predicthq/vue3.components.typography'

defineOptions({
  name: 'AccountView',
})

const store = useStore()
const router = useRouter()

const token = computed(() => store.state.signup.token)
const tokenDetails = computed(() => store.getters['signup/tokenDetails'])
const invite = computed(() => {
  if (!tokenDetails.value) return null

  const { invite_id: inviteId, invite_token: inviteToken } = tokenDetails.value
  if (inviteId && inviteToken) {
    return {
      inviteId,
      inviteToken,
    }
  }

  return null
})

onBeforeMount(() => {
  if (!token.value) router.push('/')
})
</script>
