<template>
  <div :class="bem('')">
    <h1 :class="bem('title')" class="text-h2">
      {{ title }}
    </h1>

    <div :class="bem('content')" class="text-p">
      <slot />
    </div>
  </div>
</template>
<script>
import './form-hero.scss'

export default {
  name: 'FormHero',
  props: {
    title: {
      type: String
    }
  }
}
</script>
