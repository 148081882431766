<template>
  <div :class="bem('', { large: isLarge })">
    <span :class="bem('card-box')">
      <span :class="bem('customer-logo', { dense: showDenseCustomerCard })">
        <picture v-if="typeof logoImage === 'object'">
          <source
            media="(min-width: 1025px) and (max-width: 1352px), (max-width: 380px) "
            :srcset="`${logoImage.small['1x']}, ${logoImage.small['2x']} 2x`"
          />
          <img :alt="logoImage.alt" :src="logoImage.normal['1x']" :srcset="`${logoImage.normal['2x']} 2x`" />
        </picture>

        <img
          v-if="typeof logoImage === 'string'"
          :alt="logoImageAlt"
          :src="logoImage"
          :style="{ width: logoImageWidth }"
        />
      </span>
    </span>
  </div>
</template>

<!-- This component is based on AppCustomerCard from website-ui but has limited functionality -->
<script>
export default {
  name: 'SignupCustomerCard',
  props: {
    logoImage: [Object, String],
    logoImageAlt: String,
    logoImageWidth: String,
    showDenseCustomerCard: Boolean,
    text: {
      type: String,
      default: '',
    },
    isLarge: Boolean,
  },
}
</script>
<style lang="scss" scoped src="./signup-customer-card.scss" />
