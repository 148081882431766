const storageKey = 'phq.continue'

export function getContinueUrl() {
  return sessionStorage.getItem(storageKey) ?? '/?welcome'
}

export function storeContinueUrl(url) {
  sessionStorage.setItem(storageKey, url)
}

export function removeContinueUrl() {
  sessionStorage.removeItem(storageKey)
}

export function hasContinueUrl() {
  return sessionStorage.getItem(storageKey) !== null
}
