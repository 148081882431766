import 'whatwg-fetch' // polyfill for older browsers
import queryString from 'query-string'
import config from '@/app/config'
import * as R from 'ramda'
import ApiError from './error'
import AuthRefresh from '@/app/auth/refresh'
import auth from '@/app/api/auth/auth'
import store from '@/store'
import router from '@/router'

const exchangeAuthCodeForToken = async (code) => {
  return new Promise((resolve, reject) => {
    auth.exchangeCodeForToken(`grant_type=authorization_code&client_id=${config.AUTH_CLIENT_ID}&redirect_uri=${encodeURIComponent(config.APP_URL + '/silent.html')}&code=${code}`).then(data => {
      resolve(data)
    }).catch(error => {
      reject(error)
    })
  })
}

const refreshAuth = async () => {
  return new Promise((resolve, reject) => {
    const org = store.getters['auth/org']
    const orgId = org ? org.orgId : null

    AuthRefresh.refresh({ orgId }).then(code => {
      exchangeAuthCodeForToken(code).then(data => {
        store.commit('auth/setAuthTokenDetails', data)
        resolve(store.state.auth.oauth.accessToken)
      })
    }).catch(error => {
      reject(error)
    })
  })
}

const pause = (duration) => new Promise(resolve => setTimeout(resolve, duration))

const fetchRetry = (url, fetchConfig, delay = 100, remainingRetries = 3) => {
  return fetch(url, fetchConfig).then(async response => {
    // Only retry GET requests when it's a 5xx error, or a 401 error
    if (remainingRetries > 0 && fetchConfig.method === 'get' && (response.status >= 500 && response.status <= 599)) {
      console.error(`Request failed, about to retry... (remaining retries: ${remainingRetries})`, response)

      delay = delay * 2.5
      await pause(delay)

      return fetchRetry(url, fetchConfig, delay, remainingRetries - 1)
    } else if (remainingRetries > 0 && response.status === 401 && R.has('headers', fetchConfig) && R.has('Authorization', fetchConfig.headers)) {
      // The bearer token has probably expired, attempt to get a new token
      try {
        const bearerToken = await refreshAuth()

        // Update the bearer token
        fetchConfig.headers.Authorization = `Bearer ${bearerToken}`

        return fetchRetry(url, fetchConfig, delay * 1.5, remainingRetries - 1)
      } catch (error) {
        // Cannot refresh auth for some reason so logout user
        console.log('Unable to refresh auth so logging out...', error)
        router.push('/auth/logout')
      }
    }

    return response
  }).catch(async error => {
    if (remainingRetries > 0 && fetchConfig.method === 'get') {
      console.error(`Request failed, about to retry... (remaining retries: ${remainingRetries})`, error)

      delay = delay * 2.5
      await pause(delay)

      return fetchRetry(url, fetchConfig, delay, remainingRetries - 1)
    } else {
      throw error
    }
  })
}

const client = {
  bearerToken: null,
  setBearerToken (bearerToken) {
    this.bearerToken = bearerToken
  },
  request ({ uri, params = {}, method = 'get', data = null, body = null, bearerToken = true, credentials = null }) {
    let url = `${config.AUTH_ENDPOINT}/api${uri}`

    const serviceName = R.reduce((acc, value) => {
      if (acc.length === 0 && value.trim().length > 0) {
        acc = value
      }

      return acc
    }, '', uri.split('/'))

    if (params && !R.isEmpty(params)) {
      url += '?' + queryString.stringify(params)
    }

    const conf = {
      method,
      headers: {
        Accept: 'application/json',
        'X-User-Agent': `${config.USER_AGENT}/${config.APP_VERSION}`
      }
    }

    if (credentials) {
      conf.credentials = credentials
    }
    if (bearerToken && this.bearerToken !== null) {
      conf.headers.Authorization = `Bearer ${this.bearerToken}`
    }
    if (data !== null) {
      conf.headers['Content-type'] = 'application/json'
      conf.body = JSON.stringify(data)
    }
    if (body !== null && method === 'post') {
      conf.headers['Content-type'] = 'application/x-www-form-urlencoded'
      conf.body = body
    }

    return new Promise((resolve, reject) => {
      fetchRetry(url, conf).then(response => {
        if (response.ok) {
          // Don't try read as json for 204 (no content)
          if (response.status !== 204) {
            const contentType = response.headers.get('Content-Type')

            if (R.contains('application/json', contentType)) {
              return response.json()
            } else if (R.contains('application/pdf', contentType)) {
              return response.blob()
            } else {
              return response.text()
            }
          } else {
            return response.text()
          }
        } else {
          // Handle error
          const handleError = async (response) => {
            let json = null

            try {
              json = await response.json()
            } catch (error) {
              // Error was not returned as json
              json = {}
            }

            if (R.has('error', json)) {
              throw new ApiError(json.error, {
                response: json,
                service: serviceName,
                url,
                status: response.status
              })
            } else {
              throw new ApiError('Missing "error" in response.', {
                response: json,
                service: serviceName,
                url,
                status: response.status
              })
            }
          }

          return handleError(response)
        }
      }).then(result => {
        if (result && R.has('error', result)) {
          reject(result)
        }

        resolve(result)
      }).catch(error => {
        reject(error)
      })
    })
  }
}

export default client
