import invites from '@/app/api/public/invites'
import VerifiedInvite from '@/app/invites/verified-invite'

export default {
  namespaced: true,
  state: {
    verifiedInvite: null
  },
  actions: {
    verify ({ commit }, { inviteId, inviteToken }) {
      return new Promise((resolve, reject) => {
        invites.verify(inviteId, inviteToken).then(result => {
          const invite = new VerifiedInvite(inviteId, result)

          commit('setVerifiedInvite', invite)

          resolve(result)
        }).catch(error => {
          reject(error)
        })
      })
    }
  },
  mutations: {
    setVerifiedInvite (state, invite) {
      state.verifiedInvite = invite
    }
  }
}
