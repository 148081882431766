// Heap helper to avoid having to check if heap is available in every component
// Uses the first argument as the method. Check docs: https://developers.heap.io/reference/client-side-apis-overview

// Usage examples:
// heap('addUserProperties', { email })
// heap('track', 'Purchase', { items: 2 })
// heap('identify', 'test@example.com')

export default function heap() {
  const args = [...arguments]
  const method = args.shift()
  const heap = window.heap

  if (heap) {
    if (method) return heap[method](...args)
    return heap
  }
}
