/*
    Gclid
    Offline conversion tracking
    https://support.google.com/google-ads/answer/7012522
  */
import cookie from 'cookie'

const GCLID_EXPIRY_DAYS = 90

function getParam(p) {
  const match = RegExp('[?&]' + p + '=([^&]*)').exec(window.location.search)
  return match && decodeURIComponent(match[1].replace(/\+/g, ' '))
}

function getExpiryRecord(value) {
  const expiryDate = new Date().getTime() + GCLID_EXPIRY_DAYS * 24 * 60 * 60 * 1000 // 90 day expiry in milliseconds
  return {
    value: value,
    expiryDate: expiryDate,
  }
}

export function addGclid() {
  const gclidParam = getParam('gclid')
  const gclidFormFields = ['gclid_field', 'foobar'] // all possible gclid form field ids here
  let gclidRecord = null
  let currGclidFormField = null

  const gclsrcParam = getParam('gclsrc')
  const isGclsrcValid = !gclsrcParam || gclsrcParam.indexOf('aw') !== -1

  gclidFormFields.forEach(function (field) {
    if (document.getElementById(field)) {
      currGclidFormField = document.getElementById(field)
    }
  })

  if (gclidParam && isGclsrcValid) {
    gclidRecord = getExpiryRecord(gclidParam)

    const { hostname } = window.location
    let domain = hostname
    if (hostname.endsWith('.stg.phq.io')) {
      domain = '.stg.phq.io'
    } else if (hostname.endsWith('.predicthq.com')) {
      domain = '.predicthq.com'
    }
    document.cookie = cookie.serialize('gclid', JSON.stringify(gclidRecord), {
      domain,
      path: '/',
      maxAge: GCLID_EXPIRY_DAYS * 24 * 60 * 60,
    }) // 90 day expiry in seconds
  }

  const gclid = gclidRecord || JSON.parse(cookie.parse(document.cookie)?.gclid || '{}')
  const isGclidValid = gclid && new Date().getTime() < gclid.expiryDate

  if (currGclidFormField && isGclidValid) {
    currGclidFormField.value = gclid.value
  }
}

export function getGclid() {
  const cookieGclid = cookie.parse(document.cookie)?.gclid
  return cookieGclid ? JSON.parse(cookieGclid).value : null
}
