import * as R from 'ramda'
import { merge } from 'lodash'
import jwt_decode from 'jwt-decode'
import store from '../index'
import AuthClient from '@/app/api/auth/util/client'
import AuthApiClient from '@/app/api/auth/util/api-client'
import PublicClient from '@/app/api/public/util/client'
import AuthRefresh from '@/app/auth/refresh'
import AuthUser from '@/app/auth/user'
import AuthOrg from '@/app/auth/org'

// Load initial state from localStorage
const authLocalStorageState = JSON.parse(window.localStorage.getItem('phq.auth') || '{}')

// TODO "merge" needed? - todo make sure only the fields below are allowed to be set by localstorage data
const state = merge({
  oauth: {
    accessToken: null,
    expiresIn: null,
    refreshToken: null,
    scopes: null,
    tokenType: null
  }
}, authLocalStorageState || {})

export default {
  namespaced: true,
  state,
  actions: {
    afterLogin ({ state }) {
      if (store.getters['auth/authenticated'] === true) {
        AuthClient.setBearerToken(state.oauth.accessToken)
        AuthApiClient.setBearerToken(state.oauth.accessToken)
        PublicClient.setBearerToken(state.oauth.accessToken)

        // Tell the auth refresh code the list of scopes it should be requesting
        AuthRefresh.setScopes(state.oauth.scopes)
      }
    }
  },
  getters: {
    authenticated: state => {
      return state.oauth.accessToken !== null && state.oauth.accessToken.length > 0
    },
    user: state => {
      if (state.oauth.accessToken !== null && state.oauth.accessToken.length > 0) {
        // Pull out some token details
        const jwt = jwt_decode(state.oauth.accessToken)

        if (R.has('det', jwt) && R.has('user', jwt.det)) {
          return new AuthUser(jwt.det.user)
        }
      }

      return null
    },
    org: state => {
      if (state.oauth.accessToken !== null && state.oauth.accessToken.length > 0) {
        // Pull out some token details
        const jwt = jwt_decode(state.oauth.accessToken)

        if (R.has('det', jwt) && R.has('org', jwt.det)) {
          return new AuthOrg(jwt.det.org)
        }
      }

      return null
    }
  },
  mutations: {
    setAuthTokenDetails (state, payload) {
      state.oauth.accessToken = null
      state.oauth.expiresIn = null
      state.oauth.refreshToken = null
      state.oauth.scopes = null
      state.oauth.tokenType = null

      if (R.has('access_token', payload)) {
        state.oauth.accessToken = payload.access_token
      }
      if (R.has('expires_in', payload)) {
        state.oauth.expiresIn = payload.expires_in
      }
      if (R.has('refresh_token', payload)) {
        state.oauth.refreshToken = payload.refresh_token
      }
      if (R.has('scope', payload)) {
        // Convert space separated scopes to a list because they're easier to
        // deal with throughout the app
        state.oauth.scopes = R.map(scope => scope.trim(), payload.scope.split(' '))
      }
      if (R.has('token_type', payload)) {
        state.oauth.tokenType = payload.token_type
      }

      store.dispatch('auth/afterLogin')
    },
    logout (state) {
      state.oauth.accessToken = null
      state.oauth.expiresIn = null
      state.oauth.refreshToken = null
      state.oauth.scope = null
      state.oauth.tokenType = null
    }
  }
}
