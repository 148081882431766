import { createApp } from 'vue'
import config from '@/app/config'
import router from './router'
import store from './store'
import LangPlugin from './plugins/lang'
import ProgressBarPlugin from './plugins/progress-bar'
import SystemMessagesPlugin from './plugins/system-messages'
import AutosizeDirective from './directives/autosize'
import FocusDirective from './directives/focus'
import TooltipDirective from './directives/tooltip'
import mitt from 'mitt'
import * as Sentry from '@sentry/vue'

import { defineRule, configure } from 'vee-validate'
import { localize } from '@vee-validate/i18n'
import AllRules from '@vee-validate/rules'
import { rules, messages } from '@/data/validation-rules'

import uuidMixin from '@predicthq/vue3.utils.mixin-uuid'
import bemMixin from '@predicthq/vue3.utils.mixin-bem'

import { addGclid } from './utils/captureGclid'

import App from './app.vue'

// Global main scss file
// Is used to style base things like tags - body, html, root and etc.
// Also includes reset styles
import '@/assets/scss/main.scss'

const app = createApp(App)

const emitter = mitt() // Event bus, since Vue 3 doesn't have one

app.config.globalProperties.emitter = emitter

// Set vee-validate rules globally, mix vee-validate
// default rules with our own custom rules
const validationRules = { ...AllRules, ...rules }
Object.keys(validationRules).forEach((rule) => {
  defineRule(rule, validationRules[rule])
})

// Config vee-validate
configure({
  validateOnBlur: true,
  validateOnChange: true,
  validateOnInput: false,
  validateOnModelUpdate: false,
  generateMessage: localize('en', { messages }),
})

if (config.SENTRY_ENABLED) {
  Sentry.init({
    app,
    dsn: config.SENTRY_DSN,
    release: config.APP_VERSION,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
    ],
  })
}

// Mixins
app.mixin(bemMixin)
app.mixin(uuidMixin)

app.use(router)
app.use(store)

app.use(LangPlugin)
app.use(ProgressBarPlugin)
app.use(SystemMessagesPlugin)

app.directive(AutosizeDirective)
app.directive(FocusDirective)
app.directive(TooltipDirective)

app.mount('#app')

/*
    Gclid
    Offline conversion tracking
    https://support.google.com/google-ads/answer/7012522
    Captures gclid on page load if present
  */
window.addEventListener('load', addGclid)
