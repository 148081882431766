<template>
  <div :class="bem('', { textCentered })">
    <AppHeader :variation="variation" />

    <div :class="[bem('main'), 'sides v-space-mt-5 v-space-mb-5']">
      <div :class="[bem('wrap'), wrap]">
        <div :class="[bem('body')]">
          <slot />
        </div>
      </div>
    </div>

    <div :class="[bem('bg', 'top'), 'bg-platform-left-pink']" />
    <div :class="[bem('bg', 'bottom'), 'bg-platform-left-pink']" />
  </div>
</template>

<script>
import './centered-layout.scss'
import { AppHeader } from '@/components'

export default {
  name: 'CenteredLayout',
  components: {
    AppHeader
  },
  props: {
    variation: {
      type: String,
      default: 'medium',
      validator: function (value) {
        return ['medium', 'wide'].indexOf(value) !== -1
      }
    },
    textCentered: {
      type: Boolean
    }
  },

  computed: {
    wrap () {
      switch (this.variation) {
        case 'medium':
          return 'wrap-588'
        case 'narrow':
          return 'wrap-384'
        default:
          return 'wrap'
      }
    }
  }
}
</script>
