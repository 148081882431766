import * as R from 'ramda'

const config = {
  APP_VERSION: 'dev',
  APP_URL: '',
  USER_AGENT: 'PHQ-Signup',
  AUTH_ENDPOINT: '',
  AUTH_CLIENT_ID: '',
  AUTH_INITIAL_SCOPE: [
    // Minimum scopes required to login
    'users:read',
    'memberships:read',
  ],
  AUTH_POSSIBLE_SCOPES: [
    // Max possible scopes required - will be filtered down to those the user actually has
    'users:read',
    'users:update',
    'memberships:read',
    'memberships:read:user',
    'orgs:read',
    'user-profiles:read',
    'user-profiles:update',
    'org-profiles:read',
    'org-profiles:update',
  ],
  API_ENDPOINT: '',
  PHQ_PUBLIC_API_ENDPOINT: '',
  PHQ_PUBLIC_API_ACCESS_TOKEN: '',
  APP_CONTROL_CENTER_URL: '',
  SENTRY_DSN: '',
  SENTRY_ENABLED: false,
  GTM_TRACKING_ID: '',
  HEAP_ENABLED: false,
  HEAP_APP_ID: '',

  load(config) {
    const newConfig = R.merge(this, config)

    // Update existing config
    const self = this

    R.keys(newConfig).map((key) => {
      if (key in self && typeof self[key] !== 'function') {
        self[key] = newConfig[key]
      }
    })
  },
}

// Handle webpack (dev) .env config
if (typeof process.env !== 'undefined') {
  const env = {}

  R.mapObjIndexed((value, key) => {
    if (key.startsWith('VUE_APP_')) {
      env[key.slice('VUE_APP_'.length)] = value
    }
  }, process.env)

  config.load(env)
}

// Handle runtime env.js config
if (typeof window !== 'undefined' && typeof window._runtimeEnv !== 'undefined') {
  // Convert string 1 and 0 to true/false
  const runtimeEnv = {}

  R.map((key) => {
    if (['0', '1'].includes(window._runtimeEnv[key])) {
      runtimeEnv[key] = window._runtimeEnv[key] === '1'
    } else {
      runtimeEnv[key] = window._runtimeEnv[key]
    }
  }, Object.keys(window._runtimeEnv))

  config.load(runtimeEnv)
}

export default config
