// Need to use an iframe to get a new token because the /authorize endpoint does not
// support CORS. This should be used when the user starts getting 401 responses.
import * as R from 'ramda'
import YouAreI from 'youarei'
import config from '@/app/config'

const refresh = {
  iframe: null,
  timeout: 60 * 1000,
  proxyOnIFrameMessage: {},
  backupTimer: {},
  state: Math.random().toString(36).slice(2),
  scopes: null,
  setScopes (scopes) {
    this.scopes = scopes
  },
  prepare (id, resolve, reject) {
    this.proxyOnIFrameMessage[id] = (e) => {
      this.onIFrameMessage(e, id, resolve, reject)
    }

    window.addEventListener('message', this.proxyOnIFrameMessage[id], false)

    if (this.iframe === null) {
      this.iframe = document.createElement('iframe')
      this.iframe.style.display = 'none'
      document.body.appendChild(this.iframe)
    }

    // Setup a timer as a backup in case we don't get a response from the auth service
    this.backupTimer[id] = setTimeout(() => {
      this.destroy(id)
    }, this.timeout)
  },
  destroy (id) {
    window.removeEventListener('message', this.proxyOnIFrameMessage[id], false)
    delete this.proxyOnIFrameMessage[id]

    if (this.iframe !== null) {
      document.body.removeChild(this.iframe)
      this.iframe = null
    }

    if (R.has(id, this.backupTimer)) {
      clearTimeout(this.backupTimer[id])
      delete this.backupTimer[id]
    }
  },
  onIFrameMessage (e, id, resolve, reject) {
    if (R.has('type', e.data) && R.has('location', e.data) && e.data.type === 'silent-auth-refresh') {
      const uri = new YouAreI(e.data.location)
      const params = uri.query_get()

      if (R.has('code', params) && R.has('state', params)) {
        if (params.state === this.state) {
          resolve(params.code)
        } else {
          reject(new Error('Invalid state'))
        }
      } else if (R.has('error', params)) {
        reject(new Error(params.error))
      }

      this.destroy(id)
    }
  },
  refresh ({ orgId = null } = {}) {
    return new Promise((resolve, reject) => {
      const id = Math.random().toString(36).slice(2)

      this.prepare(id, resolve, reject)

      const params = {
        response_type: 'code',
        client_id: config.AUTH_CLIENT_ID,
        scope: encodeURIComponent(this.scopes.join(' ')).replace(/%20/g, '+'),
        redirect_uri: encodeURIComponent(config.APP_URL + '/silent.html'),
        state: this.state,
        prompt: 'none'
      }

      if (orgId) {
        params.org_id = orgId
      }

      // Convert object to uri string
      const uri = R.compose(
        R.join('&'),
        R.map(R.join('=')),
        R.toPairs
      )(params)

      this.iframe.src = `${config.AUTH_ENDPOINT}/authorize?${uri}`
    })
  }
}

export default refresh
