<template>
  <div v-show="messages" class="system-messages">
    <SystemMessage
      v-for="message in messages"
      :key="message.id"
      :type="message.type"
      :text="message.text"
      :icon="message.icon"
      :actions="message.actions"
      :undo="message.undo"
    />
  </div>
</template>

<script>
import SystemMessage from './system-messages/system-message'

export default {
  name: 'SystemMessages',
  components: {
    SystemMessage,
  },
  data() {
    return {
      messages: [],
      messageId: 0,
    }
  },
  created() {
    this.emitter.on('message', (options) => {
      this.add(options.text, options.options)
    })
  },
  methods: {
    add(text, options) {
      this.messages.push({
        id: this.messageId++,
        type: options.type || 'normal',
        text: text,
        icon: options.icon || '',
        actions: options.actions || [],
        undo: options.undo || null,
      })
    },
  },
}
</script>
