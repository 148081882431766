import client from './util/client'

export default {
  exchangeCodeForToken (body) {
    return client.request({ uri: '/token', method: 'post', body, bearerToken: false })
  },
  introspect () {
    return client.request({ uri: '/introspect' })
  },
  logout () {
    return client.request({ uri: '/logout', bearerToken: false, credentials: 'include' })
  },
  selectOrg (body) {
    return client.request({ uri: '/select-org', method: 'post', body })
  },
  signup (data) {
    return client.request({ uri: '/signup', method: 'post', data })
  }
}
