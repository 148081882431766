<template>
  <div>
    <VForm v-slot="{ errors }" :class="[bem('form'), 'v-space-mt-5']" autocomplete="off" @submit="submit">
      <StatusMessage v-if="firstError(errors)" variant="error">{{ firstError(errors) }}</StatusMessage>
      <FormRow>
        <FormCell>
          <VField
            v-slot="{ componentField }"
            v-model="fields.email"
            name="email"
            label="Business Email"
            :rules="emailRules"
          >
            <AppInput
              v-bind="componentField"
              type="email"
              data-testid="email"
              label="Business Email"
              :autocomplete="false"
              :error="'email' in errors"
              :disabled="isSaving"
            />
          </VField>
        </FormCell>
      </FormRow>
      <FormRow>
        <FormCell :class="bem('password-cell')">
          <VField
            v-slot="{ componentField }"
            v-model="fields.password"
            name="password"
            label="Password"
            rules="required|min:8|has_uppercase|has_number"
          >
            <AppInput
              v-bind="componentField"
              :type="showPassword ? 'text' : 'password'"
              data-testid="password"
              label="Password"
              :disabled="isSaving"
              :error="'password' in errors"
            >
              <template #endSlot>
                <BaseButton :class="bem('show-password-btn')" @click="showPassword = !showPassword">
                  <AppIcon :icon="`form/${showPassword ? 'hide' : 'reveal'}`" :size="20" />
                </BaseButton>
              </template>
            </AppInput>
          </VField>
          <AppTypography variant="p-sm" :class="[bem('hint'), 'v-space-mb-3']">
            Password must contain at least 8 characters, 1 uppercase letter (A-Z), and 1 number (0-9)
          </AppTypography>
        </FormCell>
      </FormRow>
      <AppButton :class="bem('submit')" block type="submit" :loading="isSaving" :disabled="isSaving">
        Continue
      </AppButton>
    </VForm>
  </div>
</template>
<script>
import { useRoute, useRouter } from 'vue-router'
import { reactive, ref, computed } from 'vue'
import { FormCell, FormRow } from '@/components'
import { useStore } from 'vuex-composition-helpers'
import { Form as VForm, Field as VField } from 'vee-validate'
import { useSystemMessages } from '@/plugins/system-messages'
import AppButton from '@predicthq/vue3.components.app-button'
import AppIcon from '@predicthq/vue3.components.icon'
import AppInput from '@predicthq/vue3.components.app-input'
import AppTypography from '@predicthq/vue3.components.typography'
import BaseButton from '@predicthq/vue3.components.base-button'
import auth from '@/app/api/auth/auth'
import config from '@/app/config'
import getInterestedPlan from '@/utils/getInterestedPlan.js'
import StatusMessage from '@predicthq/vue3.components.status-message'
import useFormErrors from '@/use/form-errors'

export default {
  name: 'SignupForm',
  components: {
    AppButton,
    AppIcon,
    AppInput,
    AppTypography,
    BaseButton,
    FormCell,
    FormRow,
    VForm,
    VField,
    StatusMessage,
  },
  props: {
    content: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  setup(props) {
    const router = useRouter()
    const route = useRoute()
    const systemMessages = useSystemMessages()
    const { firstError } = useFormErrors()
    const isSaving = ref(false)
    const showPassword = ref(false)
    const store = useStore()
    const fields = reactive({
      password: '',
      email: route.query.email || '',
    })

    const emailRules = computed(() => {
      let rules = 'required|email'
      if (props.content.businessEmail) rules = `${rules}|business_email`
      return rules
    })

    function logout() {
      auth.logout().then(() => {
        store.commit('auth/logout')
      })
    }

    async function submit() {
      isSaving.value = true
      try {
        await store.dispatch('signup/signup', {
          email: fields.email,
        })

        // Store the password as it will be used on the next step to create their account
        store.commit('signup/setPassword', fields.password)

        const query = {}
        const plan = getInterestedPlan(route.query)
        if (plan) query.plan = plan

        router.push({ path: '/account', query })
      } catch (e) {
        let message = 'An error occurred. Try again later'
        if (e.status === 400) message = e.message
        systemMessages.show(message)

        console.error(e)
        isSaving.value = false
      }
    }

    // Make sure user is not currently logged in
    logout()

    return {
      config,
      emailRules,
      fields,
      firstError,
      isSaving,
      showPassword,
      submit,
    }
  },
}
</script>

<style scoped lang="scss" src="./signup-form.scss" />
