const autosize = (el) => {
  if (el.scrollHeight > el.clientHeight) {
    el.style.height = `${el.scrollHeight}px`
  }
}

export default {
  mounted(el) {
    autosize(el)
  },
  updated(el) {
    autosize(el)
  },
}
