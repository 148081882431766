import config from '@/app/config'
import phqApiClient from '@/app/api/phq-public/util/client'
import places from '@/app/api/phq-public/places'
import PublicApiPlacesSearchResult from '@/app/phq-public/places/search-result'

export default {
  namespaced: true,
  state: {
    places: {},
  },
  actions: {
    search(_, params) {
      // TODO temp solution for searching places without JWT
      phqApiClient.setBearerToken(config.PHQ_PUBLIC_API_ACCESS_TOKEN)

      return new Promise((resolve, reject) => {
        places
          .search(params)
          .then((result) => {
            const searchResult = new PublicApiPlacesSearchResult(result)

            resolve(searchResult)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    get({ commit }, placeId) {
      // TODO temp solution for searching places without JWT
      phqApiClient.setBearerToken(config.PHQ_PUBLIC_API_ACCESS_TOKEN)

      return new Promise((resolve, reject) => {
        places
          .search({
            id: placeId,
            limit: 1,
          })
          .then((result) => {
            const searchResult = new PublicApiPlacesSearchResult(result)

            if (searchResult.count === 1) {
              commit('addPlace', searchResult.places[0])
              resolve(searchResult.places[0])
            } else {
              reject(new Error('Not able to find place'))
            }
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
  },
  mutations: {
    addPlace(state, place) {
      state.places = {
        ...state.places,
        [place.placeId]: place,
      }
    },
  },
}
