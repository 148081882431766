<template>
  <div :class="bem('', { large: isLarge })">
    <img v-if="image" :src="imagePath" :class="bem('image')" />
    <div :class="bem('author')">
      <span v-if="name" :class="[bem('name'), 'text-p-sm']">{{ name }}</span>

      <div v-if="position" :class="[bem('position-wrap'), 'text-p-sub']">
        <span :class="bem('position')">{{ position }}</span>
        <span v-if="company">,&nbsp;{{ company }}</span>
      </div>
    </div>
  </div>
</template>

<!-- This component is based on AppAuthor from website-ui but has limited functionality -->
<script>
export default {
  name: 'SignupAuthor',
  props: {
    image: String,
    imageLarge: String,
    name: String,
    position: String,
    isLarge: Boolean,
    company: String,
  },
  computed: {
    imagePath() {
      if (new RegExp('//images').test(this.image)) {
        return this.image
      }

      if (!this.isLarge) {
        return this.image
      } else {
        return this.imageLarge
      }
    },
  },
}
</script>

<style lang="scss" scoped src="./signup-author.scss" />
