<template>
  <section :class="bem('', { staggered })">
    <div class="wrap">
      <AppTypography v-if="label" variant="label" :class="[bem('label'), 'text-label']">{{ label }}</AppTypography>
      <ul :class="[bem('cards-list', `${featuredCustomers.length}`)]">
        <li v-for="(item, idx) in featuredCustomers" :key="idx" :class="[bem('card'), col]">
          <SignupCustomerCard v-bind="item" :isLarge="featuredCustomers.length === 4" />
        </li>
      </ul>
    </div>
  </section>
</template>

<!-- This component is based on FeaturedCustomers from website-ui but has limited functionality -->
<script>
import { SignupCustomerCard } from '@/components'
import AppTypography from '@predicthq/vue3.components.typography'

export default {
  name: 'SignupFeaturedCustomers',
  components: {
    AppTypography,
    SignupCustomerCard,
  },
  props: {
    label: {
      type: String,
      default: 'World #1 Demand intelligence platform used by',
    },
    featuredCustomers: {
      type: Array,
      required: true,
    },
    staggered: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    col() {
      const { length } = this.featuredCustomers
      let t
      if (length === 4) t = 'col-t-6 col-l-3'
      else if (length > 6) t = 'col-t-4 col-l-2.4'
      else t = 'col-t-2 col-l-2'
      return `col-m-6 ${t}`
    },
  },
}
</script>

<style lang="scss" scoped src="./signup-featured-customers.scss" />
