import * as R from 'ramda'
import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'

// Views
import {
  AccountView,
  CompleteView,
  InviteView,
  LoginView,
  LogoutView,
  OpenidView,
  SignupView,
  ErrorView,
} from '@/views'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      component: SignupView,
      meta: {
        requireAuth: false,
        title: 'Signup',
      },
    },
    {
      path: '/auth',
      name: 'auth',
      redirect: '/auth/login',
    },
    {
      path: '/auth/logout',
      component: LogoutView,
      meta: {
        requireAuth: false,
      },
    },
    {
      path: '/auth/login',
      component: LoginView,
      meta: {
        requireAuth: false,
        title: 'Sign in',
      },
    },
    {
      path: '/account',
      component: AccountView,
      meta: {
        requireAuth: false,
      },
    },
    {
      path: '/account/complete',
      component: CompleteView,
      meta: {
        requireAuth: false,
      },
    },
    {
      path: '/openid',
      component: OpenidView,
      meta: {
        requireAuth: false,
      },
    },
    {
      path: '/invite/:inviteId/:inviteToken',
      component: InviteView,
      meta: {
        requireAuth: false,
      },
    },
    {
      // Old path, redirect to invite for backwards compatibility
      path: '/user/:inviteId/:inviteToken',
      redirect: (to) => {
        return `/invite/${to.params.inviteId}/${to.params.inviteToken}`
      },
    },
    {
      path: '/:pathMatch(.*)*',
      name: '404',
      component: ErrorView,
      meta: {
        requireAuth: false,
      },
    },
  ],
})

router.beforeEach((to, from, next) => {
  const requireAuth = R.has('meta', to) && R.has('requireAuth', to.meta) ? to.meta.requireAuth : true

  if (!requireAuth || store.getters['auth/authenticated'] === true) {
    next()
  } else {
    // Store current page so we can redirect after login
    window.localStorage.setItem('phq.auth.continue', to.fullPath)

    next('/auth/login')
  }
})

// Handle page title changes
router.afterEach((to) => {
  if (window.piTracker) window.piTracker()
  if ('meta' in to && 'title' in to.meta) {
    store.commit('route/item', {
      name: to.meta.title,
    })
  } else {
    store.commit('route/item', {
      name: '',
    })
  }
})

export default router
