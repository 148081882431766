/*
  Pardot Visitor ID
  https://help.salesforce.com/s/articleView?id=sf.pardot_basics_cookies.htm&type=5
*/
import cookie from 'cookie'

// Extracting Pardot cookies. They start with `visitor_id<Pardot Account ID>`
export default function () {
  const cookies = cookie.parse(document.cookie)

  const pardotCookies = Object.keys(cookies)
    .filter(key => key.startsWith('visitor_id'))
    .reduce((obj, key) => {
      obj[key] = cookies[key]
      return obj
    }, {})

  return Object.keys(pardotCookies).length ? pardotCookies : null
}
