// https://github.com/yahoo/intl-messageformat
// https://formatjs.io/
export default {
  'category.school-holidays.name': 'School holidays',
  'category.school-holidays.description': '<a href="https://support.predicthq.com/what-countries-do-you-have-school-holidays-for" target="_blank">See a full list</a> of all the countries we currently have school holiday data for.',
  'category.public-holidays.name': 'Public holidays',
  'category.public-holidays.description': 'These are special days that usually mean a day off work, like Christmas Day.',
  'category.observances.name': 'Observances',
  'category.observances.description': 'These are remembrance, awareness and celebration-type days where you don\'t normally get a day off work, like Valentine\'s Day.',
  'category.concerts.name': 'Concerts',
  'category.concerts.description': 'These could be anything from small local gigs at the pub down the road, right through to big-budget international artists.',
  'category.conferences.name': 'Conferences',
  'category.conferences.description': 'These could be anything from small gatherings to large scale conferences that attract international interest.',
  'category.expos.name': 'Expos',
  'category.expos.description': 'These cover anything from expos, exhibitions, trade shows, trade fairs and the like.',
  'category.terror.name': 'Terror',
  'category.terror.description': 'This covers confirmed and suspected terrorist attacks mostly in top tourist destinations.',
  'category.sports.name': 'Sports',
  'category.sports.description': 'These are notable sports matches and championships, like soccer, baseball, tennis, basketball and other major sports.',
  'category.daylight-savings.name': 'Daylight savings',
  'category.daylight-savings.description': 'If you’re anything like us, you probably like sleeping in. If you\'re interested we\'ll tell you when daylight savings begins and ends.',
  'category.airport-delays.name': 'Airport delays',
  'category.airport-delays.description': 'This covers both domestic and international airport delays.',
  'category.severe-weather.name': 'Severe weather',
  'category.severe-weather.description': 'We\'re talking cyclones, tornadoes, hurricanes, blizzards, storms, and everything else in between.',
  'category.disasters.name': 'Disasters',
  'category.disasters.description': 'If disaster strikes in the form of tsunamis, earthquakes, flooding, volcanic eruptions or avalanches, it’s important to be informed.',
  'category.festivals.name': 'Festivals',
  'category.festivals.description': 'These are event dates for various festivals, carnivals and celebrations happening all around the world.',
  'category.performing-arts.name': 'Performing arts',
  'category.performing-arts.description': 'These are event dates for plays, theatre performances, musicals, comedy shows and everything in between.',
  'category.community.name': 'Community',
  'category.community.description': 'These cover events such as workshops, classes, lessons and group meetups.',
  'category.politics.name': 'Politics',
  'category.politics.description': 'This covers parliamentary and presidential elections, as well as referendums.',

  'rank.level1.name': 'Minor',
  'rank.level2.name': 'Moderate',
  'rank.level3.name': 'Important',
  'rank.level4.name': 'Significant',
  'rank.level5.name': 'Major',

  'billing.product.api.heading': 'API Plan',
  'billing.product.data.heading': 'API Plan',
  'billing.product.app.heading': 'Web App Plan',
  'billing.product.focus.heading': 'FOCUS Plan',

  'billing.plans.app-free.name': 'Free',
  'billing.plans.app-free.name.full': 'WebApp - Free',
  'billing.plans.app-starter.name': 'Starter',
  'billing.plans.app-starter.name.full': 'WebApp - Starter',
  'billing.plans.app-standard.name': 'Standard',
  'billing.plans.app-standard.name.full': 'WebApp - Standard',
  'billing.plans.app-plus.name': 'Plus',
  'billing.plans.app-plus.name.full': 'WebApp - Plus',
  'billing.plans.app-premium.name': 'Premium',
  'billing.plans.app-premium.name.full': 'WebApp - Premium',
  'billing.plans.app-enterprise.name': 'Enterprise',
  'billing.plans.app-enterprise.name.full': 'WebApp - Enterprise',

  // Event Search - Legacy v1
  'billing.plans.api-starter.name': 'Legacy Starter',
  'billing.plans.api-starter.name.full': 'API - Legacy Starter',
  'billing.plans.api-enterprise.name': 'Legacy Enterprise',
  'billing.plans.api-enterprise.name.full': 'API - Legacy Enterprise',

  // Event Search - Legacy v2
  'billing.plans.api-search-trial.name': 'Legacy Free Trial',
  'billing.plans.api-search-trial.name.full': 'Event Search - Legacy Free Trial',
  'billing.plans.api-search-starter.name': 'Legacy Starter',
  'billing.plans.api-search-starter.name.full': 'Event Search - Legacy Starter',
  'billing.plans.api-search-standard.name': 'Legacy Standard',
  'billing.plans.api-search-standard.name.full': 'Event Search - Legacy Standard',
  'billing.plans.api-search-plus.name': 'Legacy Plus',
  'billing.plans.api-search-plus.name.full': 'Event Search - Legacy Plus',

  // Event Search - Current v3
  'billing.plans.api-search-v3-developer.name': 'Developer',
  'billing.plans.api-search-v3-developer.name.full': 'Event Search - Developer',
  'billing.plans.api-search-v3-starter.name': 'Starter',
  'billing.plans.api-search-v3-starter.name.full': 'Event Search - Starter',
  'billing.plans.api-search-v3-standard.name': 'Standard',
  'billing.plans.api-search-v3-standard.name.full': 'Event Search - Standard',
  'billing.plans.api-search-v3-plus.name': 'Plus',
  'billing.plans.api-search-v3-plus.name.full': 'Event Search - Plus',

  'billing.plans.api-search-premium.name': 'Premium',
  'billing.plans.api-search-premium.name.full': 'Event Search - Premium',

  'billing.plans.api-data.name': 'Premium Plan',
  'billing.plans.api-data.name.full': 'Premium Plan',
  'billing.plans.api-data-trial.name': 'Premium Plan - Trial',
  'billing.plans.api-data-trial.name.full': 'Premium Plan - Trial'
}
