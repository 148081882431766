import * as R from 'ramda'

function AuthOrg (data) {
  const org = {
    orgId: R.has('org_id', data) ? data.org_id : null,
    name: R.has('name', data) ? data.name : null
  }

  return org
}

export default AuthOrg
