<template>
  <div :class="bem('', variant)">
    <h2 :class="bem('label')" class="text-label-lg">Your chosen plan</h2>
    <div :class="bem('card')">
      <h3 :class="bem('title')">{{ planData.title }}</h3>
      <p :class="[bem('access'), 'text-p-sm']" v-html="planData.access" />
      <AppChip class="chip-trial" variant="pastel-purple">{{ planData.chip }}</AppChip>
    </div>
    <p :class="[bem('description'), 'text-p-sm']">{{ planData.description }}</p>
  </div>
</template>

<script>
import AppChip from '@predicthq/vue3.components.app-chip'

export default {
  name: 'PlanCard',
  components: {
    AppChip,
  },
  props: {
    variant: {
      type: String,
      default: '',
      validator: (value) => ['free', 'lite', 'premium'].includes(value),
    },
  },
  data() {
    return {
      plans: {
        free: {
          title: 'Free Plan',
          access: 'Access to our <strong>Control Center</strong> only',
          chip: '14 Day Free Trial',
          description:
            'Your plan starts with an all-access 14 day free trial. After the trial period, you will be moved to our Free plan with access to 3 locations.',
        },
        lite: {
          title: 'Lite Plan',
          access: 'Access to our <strong>Control Center</strong> only',
          chip: '14 Day Free Trial',
          description:
            'Your plan starts with an all-access 14 day free trial. During this time, our team of experts will get in touch to provide the best pricing for your business use case.',
        },
        premium: {
          title: 'Premium Plan',
          access: 'Access to our <strong>Control Center</strong> and <strong>APIs</strong>',
          chip: '14 Day Free Trial',
          description:
            'Your plan starts with an all-access 14 day free trial. During this time, our team of experts will get in touch to provide the best pricing for your business use case.',
        },
      },
    }
  },
  computed: {
    planData() {
      return this.plans[this.variant]
    },
  },
}
</script>
<style scoped lang="scss" src="./plan-card.scss" />
