import client from './util/api-client'

export default {
  get (userId) {
    return client.request({ uri: `/users/${userId}` })
  },
  update (userId, data) {
    return client.request({ uri: `/users/${userId}`, method: 'put', data })
  },
  changePassword (userId, data) {
    return client.request({ uri: `/users/${userId}/change-password`, method: 'post', data })
  },
  search (params) {
    return client.request({ uri: '/users', params })
  },
  memberships (userId, params) {
    return client.request({ uri: `/users/${userId}/memberships`, params })
  }
}
