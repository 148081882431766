import { inject } from 'vue'
import * as R from 'ramda'
import NProgress from 'nprogress'

const key = Symbol('progressBar')

export const useProgressBar = () => inject(key)

export default {
  install(app) {
    NProgress.configure({
      showSpinner: false,
      minimum: 0.2,
      speed: 600,
      // easing: 'ease'
    })

    const progressBar = {
      show() {
        // Animate the PredictHQ logo
        R.map((el) => {
          el.classList.add('loading')
        }, document.getElementsByClassName('logo'))

        R.map((el) => {
          el.classList.add('loading')
        }, document.getElementsByClassName('full-logo'))

        return NProgress.start()
      },
      hide() {
        // Stop animating the PredictHQ logo
        R.map((el) => {
          el.classList.remove('loading')
        }, document.getElementsByClassName('logo'))

        R.map((el) => {
          el.classList.remove('loading')
        }, document.getElementsByClassName('full-logo'))

        return NProgress.done()
      },
    }

    app.config.globalProperties.$progressBar = progressBar

    app.provide(key, progressBar)
  },
}
