<template>
  <component :is="el" :class="bem('', [modifier, { inline: inline, submitting: submitting }])" v-bind="linkProps">
    <span v-if="submitting" :class="bem('loader')">
      <span :class="bem('loader-dot')"></span>
      <span :class="bem('loader-dot')"></span>
      <span :class="bem('loader-dot')"></span>
    </span>
    <template v-if="!submitting">
      <span :class="bem('content')">
        <span v-if="$slots.startIcon" :class="bem('icon')"><slot name="startIcon" /></span>
        <slot />
      </span>
    </template>
  </component>
</template>

<script>
export default {
  name: 'AppButton',
  props: {
    inline: {
      type: Boolean,
      default: true,
    },
    modifier: {
      type: String,
      default: 'primary',
      validator: function (value) {
        // The value must match one of these strings
        return ['primary', 'secondary', 'plain'].indexOf(value) !== -1
      },
    },
    type: {
      type: String,
      default: 'button',
    },
    icon: {
      type: String,
    },
    url: {
      type: String,
    },
    submitting: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    el() {
      if (this.url) {
        if (new RegExp('http').test(this.url)) {
          return 'a'
        } else {
          return 'router-link'
        }
      } else {
        return 'button'
      }
    },
    href() {
      return this.el !== 'router-link' ? this.url : null
    },
    to() {
      return this.el === 'router-link' ? this.url : null
    },
    linkProps() {
      const props = {
        type: this.type,
      }

      if (this.href) {
        props.href = this.href
      } else if (this.to) {
        props.to = this.to
      }

      return props
    },
  },
}
</script>

<style lang="scss" scoped src="./app-button.scss" />
