import * as R from 'ramda'

function AuthUser (data) {
  const user = {
    userId: R.has('user_id', data) ? data.user_id : null,
    email: R.has('email', data) ? data.email : null,
    firstName: R.has('first_name', data) ? data.first_name : null,
    lastName: R.has('last_name', data) ? data.last_name : null,
    get name () {
      const parts = []

      if (this.firstName) {
        parts.push(this.firstName)
      }
      if (this.lastName) {
        parts.push(this.lastName)
      }

      return parts.join(' ')
    },
    get initials () {
      const initials = []

      if (this.firstName) {
        initials.push(this.firstName.slice(0, 1).toUpperCase())
      }
      if (this.lastName) {
        initials.push(this.lastName.slice(0, 1).toUpperCase())
      }

      return initials.join('')
    }
  }

  return user
}

export default AuthUser
