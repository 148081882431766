<template>
  <VForm v-slot="{ errors }" :class="bem('')" autocomplete="off" @submit="submit">
    <StatusMessage v-if="(error = firstError(errors)) || errorMsg" class="v-space-mt-3" variant="error">
      {{ error || errorMsg }}
    </StatusMessage>

    <FormRow v-if="!tokenDetails.provider && !store.state.signup.password">
      <FormCell>
        <VField
          v-slot="{ componentField }"
          v-model="fields.password"
          name="password"
          label="Password"
          rules="required|password"
        >
          <AppInput
            v-bind="componentField"
            id="password"
            :type="showPassword ? 'text' : 'password'"
            :disabled="isSubmitting"
            :error="'password' in errors"
            label="Password *"
          >
            <template #endSlot>
              <BaseButton :class="bem('show-password-btn')" @click="showPassword = !showPassword">
                <AppIcon :icon="`form/${showPassword ? 'hide' : 'reveal'}`" :size="20" />
              </BaseButton>
            </template>
          </AppInput>

          <AppTypography variant="p-sm" :class="bem('password-hint')">
            Password must contain at least 8 characters, 1 uppercase letter (A-Z), and 1 number (0-9)
          </AppTypography>
        </VField>
      </FormCell>
    </FormRow>

    <FormRow>
      <FormCell :modifiers="['6']">
        <VField
          v-slot="{ componentField }"
          v-model="fields.firstName"
          name="firstName"
          label="First Name"
          rules="required"
        >
          <AppInput
            v-bind="componentField"
            id="firstName"
            label="First Name *"
            :error="'firstName' in errors"
            :disabled="isSubmitting"
          />
        </VField>
      </FormCell>

      <FormCell :modifiers="['6']">
        <VField
          v-slot="{ componentField }"
          v-model="fields.lastName"
          name="lastName"
          label="Last Name"
          rules="required"
        >
          <AppInput
            v-bind="componentField"
            id="lastName"
            name="lastName"
            label="Last Name *"
            :error="'lastName' in errors"
            :disabled="isSubmitting"
          />
        </VField>
      </FormCell>
    </FormRow>

    <FormRow>
      <FormCell>
        <VField v-slot="{ componentField }" v-model="fields.role" name="role" label="Your Role" rules="required">
          <AppSelect
            v-bind="componentField"
            id="role"
            label="Your Role *"
            :error="'role' in errors"
            :disabled="isSubmitting"
            :items="roles"
          />
        </VField>
      </FormCell>
    </FormRow>

    <template v-if="!invite">
      <FormRow>
        <FormCell>
          <VField v-slot="{ componentField }" v-model="fields.orgName" name="org" label="Company Name" rules="required">
            <AppInput
              v-bind="componentField"
              id="org"
              label="Company Name *"
              :error="'org' in errors"
              :disabled="isSubmitting"
            />
          </VField>
        </FormCell>
      </FormRow>

      <FormRow>
        <FormCell>
          <VField
            v-slot="{ value, handleChange }"
            v-model="fields.industry"
            name="companyIndustry"
            label="Company's Industry"
            rules="required"
          >
            <AppSelect
              id="company-industry"
              :modelValue="value"
              :error="'companyIndustry' in errors"
              :disabled="isSubmitting"
              :items="industries"
              label="Company's Industry *"
              @update:modelValue="handleChange"
            />
          </VField>
        </FormCell>
      </FormRow>

      <FormRow>
        <FormCell>
          <VField
            v-slot="{ value, handleChange }"
            v-model="fields.companySize"
            name="companySize"
            label="Company Size"
            rules="required"
          >
            <AppSelect
              id="company-size"
              :modelValue="value"
              :error="'companySize' in errors"
              :disabled="isSubmitting"
              :items="companySizeOptions"
              label="Company Size *"
              @update:modelValue="handleChange"
            />
          </VField>
        </FormCell>
      </FormRow>
    </template>

    <FormRow v-if="invite && tokenDetails.email && !tokenDetails.provider">
      <FormCell>
        <AppTypography variant="p-sm">
          Your email address is
          <strong>{{ tokenDetails.email }}</strong>
        </AppTypography>
      </FormCell>
    </FormRow>

    <FormRow>
      <FormCell>
        <AppButton type="submit" block :disabled="isSubmitting" :loading="isSubmitting">
          Create account{{ invite ? ' and accept invite' : '' }}
        </AppButton>
      </FormCell>
    </FormRow>

    <AppTypography variant="p-sm" class="v-space-mt-5 text-center">
      By signing up, you agree to PredictHQ's
      <a href="https://www.predicthq.com/legal/terms" target="_blank">Terms&nbsp;of&nbsp;Service</a>
      and
      <a href="https://www.predicthq.com/legal/privacy" target="_blank">Privacy&nbsp;Policy</a>
      .
    </AppTypography>
  </VForm>

  <form
    v-if="tokenDetails.email && fields.password"
    ref="loginForm"
    :action="`${config.AUTH_ENDPOINT}/login`"
    method="post"
  >
    <input type="hidden" name="email" :value="tokenDetails.email" />

    <input type="hidden" name="password" :value="fields.password" />

    <input type="hidden" name="continue" :value="landingUrl" />
  </form>
</template>

<script setup>
import { computed, onMounted, ref, reactive } from 'vue'
import { Form as VForm, Field as VField } from 'vee-validate'
import { FormCell, FormRow } from '@/components'
import { getContinueUrl, hasContinueUrl } from '@/utils/continueUrl'
import { getGclid } from '@/utils/captureGclid.js'
import { TIME_ZONES } from '@/app/tz'
import { useProgressBar } from '@/plugins/progress-bar'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import ApiError from '@/app/api/public/util/error'
import AppButton from '@predicthq/vue3.components.app-button'
import AppIcon from '@predicthq/vue3.components.icon'
import AppInput from '@predicthq/vue3.components.app-input'
import AppSelect from '@predicthq/vue3.components.app-select'
import AppTypography from '@predicthq/vue3.components.typography'
import BaseButton from '@predicthq/vue3.components.base-button'
import config from '@/app/config'
import getInterestedPlan from '@/utils/getInterestedPlan.js'
import getPardotCookies from '@/utils/getPardotCookies.js'
import gtag from '@/utils/gtag'
import heap from '@/utils/heap'
import industriesList from '@/data/industries.json'
import moment from 'moment-timezone'
import roles from '@/data/roles.json'
import signup from '@/app/api/public/signup'
import StatusMessage from '@predicthq/vue3.components.status-message'
import useExperiment from '@/use/experiment'
import useFormErrors from '@/use/form-errors'

const companySizeOptions = [
  { value: '<25', text: '<25 employees' },
  { value: '25-249', text: '25-249 employees' },
  { value: '250+', text: '250+ employees' },
]

const props = defineProps({
  invite: Object,
})

defineOptions({
  name: 'FormAccount',
})

const store = useStore()
const route = useRoute()
const experiment = useExperiment()

const progressBar = useProgressBar()
const { firstError } = useFormErrors()

const errorMsg = ref(null)
const industries = ref([...industriesList.map((x) => x.name).sort(), 'Other'])
const isSubmitting = ref(false)
const loginForm = ref(null)
const showPassword = ref(false)

const token = computed(() => store.state.signup.token)
const tokenDetails = computed(() => store.getters['signup/tokenDetails'])
const interestedPlan = computed(() => getInterestedPlan(route.query))

const landingUrl = computed(() => {
  const continueUrl = getContinueUrl()
  const url = new URL(`${config.APP_CONTROL_CENTER_URL}${continueUrl}`)

  if (!hasContinueUrl() && !props.invite) url.searchParams.set('create-location-modal', 'true')

  return url.toString()
})

const fields = reactive({
  firstName: '',
  lastName: '',
  password: store.state.signup.password ?? '',
  industry: '',
  role: '',
  orgName: '',
  companySize: '',
})

function getPayload() {
  const { firstName, lastName, role, password, orgName, companySize } = fields

  const payload = {
    signup_token: token.value,
    first_name: firstName,
    last_name: lastName,
    additional_data: {
      role,
      gclid_field: getGclid(),
      pardot_cookies: getPardotCookies(),
      interested_plan: interestedPlan.value,
    },
  }

  if (props.invite) {
    payload.invite = {
      invite_id: props.invite.inviteId,
      invite_token: props.invite.inviteToken,
      action: 'accept',
    }
  } else {
    const industry = industriesList.find(({ name }) => name.toLowerCase() === fields.industry.toLowerCase().trim())

    payload.org = {
      name: orgName,
      plan: 'data-dev-seattle',
      profile: {
        intended_use: 'business',
      },
    }

    if (industry) payload.org.profile.industry_id = industry.industry_id
    else payload.org.profile.other_industry = fields.industry
    payload.additional_data.company_size = companySize
    if (experiment.hasStarted) payload.additional_data.ab_test = `${experiment.id}:${experiment.variant.value}`
  }

  if (!tokenDetails.value.provider) payload.password = password

  const tz = moment.tz.guess()

  if (TIME_ZONES.includes(tz)) payload.profile = { tz }

  return payload
}

async function submit() {
  try {
    progressBar.show()
    isSubmitting.value = true

    const data = getPayload()
    const { provider } = tokenDetails.value

    await signup.signup(data)

    gtag('event', 'signup_completed')
    heap('addUserProperties', { email: data.email, signupType: props.invite ? 'invited' : 'new' })
    heap('track', 'Created Account', { authType: provider ?? 'form' })

    if (loginForm.value) loginForm.value.submit()
    else if (provider)
      window.location = `${config.AUTH_ENDPOINT}/login/${provider}?continue=${encodeURIComponent(landingUrl.value)}`
    else window.location = landingUrl.value
  } catch (error) {
    console.error(error)
    let message = 'Error creating new user'

    if (error instanceof ApiError) message = error.message

    errorMsg.value = message
    isSubmitting.value = false
    progressBar.hide()
  }
}

function prepare() {
  if (tokenDetails.value) {
    const { first_name: firstName, last_name: lastName } = tokenDetails.value
    if (firstName) fields.firstName = firstName
    if (lastName) fields.lastName = lastName
  }
}

onMounted(prepare)
</script>

<style scoped lang="scss" src="./form-account.scss" />
