<template>
  <CenteredLayout>
    <AppSpinner v-if="isLoading" center />
    <FormHero v-else-if="errorMsg" title="Invite error">
      <AppTypography>{{ errorMsg }}</AppTypography>
      <AppTypography>Invites expire after around 3 days. Please request another invite.</AppTypography>
    </FormHero>
    <FormHero
      v-else-if="verifiedInvite && !verifiedInvite.existingUser"
      :title="`Join the ${verifiedInvite.orgName} team`"
    >
      <AppTypography class="v-space-mb-3">
        You have been invited by {{ verifiedInvite.inviterEmail }} to join your
        <strong>{{ verifiedInvite.orgName }}</strong> team at PredictHQ.
      </AppTypography>

      <FormAccount v-bind="{ invite }" />
      <AppTypography variant="p-sm" class="c-light text-center v-space-mt-3">– OR sign up with –</AppTypography>
      <AppButtonGroup class="v-space-mt-3">
        <AppButton
          v-for="(provider, key) in authProviders"
          :key="`btn-${key}`"
          :inline="false"
          modifier="plain"
          :url="getProviderUrl(key)"
        >
          <template #startIcon>
            <AppIcon :icon="`social/${key}`" inline color="current" class="c-brand-purple" />
          </template>
          <span class="hidden-m">{{ provider }}</span>
        </AppButton>
      </AppButtonGroup>
      <AppTypography class="v-space-mt-5 v-space-mb-3">
        If you already have an existing account with a different email address, please request a new invite from the
        team at
        <strong> {{ verifiedInvite.orgName }} </strong>.
      </AppTypography>
      <AppTypography>
        If you need help, please contact our team for support.
        <a href="https://www.predicthq.com/contact" target="_blank">Contact us</a>
      </AppTypography>
    </FormHero>
  </CenteredLayout>
</template>

<script>
import config from '@/app/config'
import ApiError from '@/app/api/public/util/error'

import { CenteredLayout } from '@/layouts'
import { AppButtonGroup, AppButton, FormAccount, FormHero, AppSpinner } from '@/components'
import authProviders from '@/data/auth-providers'
import { ref, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import AppIcon from '@predicthq/vue3.components.icon'
import AppTypography from '@predicthq/vue3.components.typography'

export default {
  name: 'InviteView',
  components: {
    AppButton,
    AppButtonGroup,
    AppIcon,
    AppSpinner,
    AppTypography,
    FormAccount,
    FormHero,
    CenteredLayout,
  },
  setup() {
    const route = useRoute()
    const store = useStore()
    const errorMsg = ref(null)
    const isLoading = ref(true)

    const invite = computed(() => {
      const { inviteId, inviteToken } = route.params
      if (!inviteId || !inviteToken) return null

      return {
        inviteId,
        inviteToken,
      }
    })

    const verifiedInvite = computed(() => store.state.invite.verifiedInvite)

    // Params used in the login page for existing users
    const loginInviteUrl = computed(() => {
      const { email, inviterEmail, orgName } = verifiedInvite.value

      const params = new URLSearchParams({
        email,
        inviteId: invite.value.inviteId,
      })

      if (inviterEmail) params.set('invitedBy', inviterEmail)

      if (orgName) params.set('orgName', orgName)

      return `${config.AUTH_ENDPOINT}/login?${params.toString()}`
    })

    async function verifyInvite() {
      try {
        await store.dispatch('invite/verify', invite.value)

        const { email, existingUser } = verifiedInvite.value

        // Redirect existing user to login page with the invite details
        if (existingUser) window.location = loginInviteUrl.value
        else await store.dispatch('signup/signup', { email })
      } catch (e) {
        console.error(e)
        let message = 'Error verifying invite'

        if (e instanceof ApiError) {
          message = e.status === 410 ? 'Invite is no longer valid. It may have expired or been canceled.' : e.message
        }
        errorMsg.value = message
      } finally {
        isLoading.value = false
      }
    }

    function getProviderUrl(provider) {
      const params = new URLSearchParams({
        continue: `${config.APP_URL}/openid`,
        inviteId: invite.value.inviteId,
        inviteToken: invite.value.inviteToken,
      })

      return `${config.AUTH_ENDPOINT}/signup/${provider}?${params.toString()}`
    }

    onMounted(verifyInvite)

    return {
      authProviders,
      config,
      errorMsg,
      invite,
      isLoading,
      getProviderUrl,
      verifiedInvite,
    }
  },
}
</script>
