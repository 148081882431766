<template>
  <component :is="linkEl" :to="url" :href="url" @click="scrollTo">
    <slot />
  </component>
</template>
<script>
export default {
  name: 'LinkCheck',
  props: {
    url: String,
  },
  computed: {
    linkEl() {
      if (!this.url) return 'div'
      if (
        new RegExp('^https?').test(this.url) ||
        new RegExp('^#').test(this.url) ||
        this.url.endsWith('.pdf') ||
        this.url.endsWith('.zip')
      )
        return 'a'

      return 'g-link'
    },
  },
  methods: {
    scrollTo(e) {
      if (new RegExp('^#').test(this.url)) {
        e.preventDefault()
        document.querySelector(this.url).scrollIntoView({ behavior: 'smooth' })
      }
    },
  },
}
</script>
