export default {
  'accor-hotels': {
    logoImage: require('@/assets/images/logos/logo-accorhotels.svg'),
    logoImageAlt: 'Accor Hotels',
  },
  'baywood-hotels': {
    logoImage: require('@/assets/images/logos/logo-baywood-hotels.png'),
    logoImageAlt: 'Baywood Hotels',
  },
  'blue-yonder': {
    logoImage: require('@/assets/images/logos/logo-blueyonder.svg'),
    logoImageAlt: 'Blue Yonder',
  },
  dominos: {
    logoImage: require('@/assets/images/logos/logo-dominos.svg'),
    logoImageAlt: `Domino's`,
  },
  duetto: {
    logoImage: require('@/assets/images/logos/logo-duetto.svg'),
    logoImageAlt: `Duetto`,
  },
  'gps-parking': {
    logoImage: require('@/assets/images/logos/logo-gps-parking.png'),
    logoImageAlt: `Gateway Parking Services`,
    showDenseCustomerCard: true,
  },
  justpark: {
    logoImage: require('@/assets/images/logos/logo-justpark.svg'),
    logoImageAlt: 'JustPark',
  },
  'kalahari-resorts': {
    logoImage: require('@/assets/images/logos/logo-kalahari-resorts.png'),
    logoImageAlt: `Kalahari Resorts`,
    showDenseCustomerCard: true,
  },
  'ota-insight': {
    logoImage: require('@/assets/images/logos/logo-ota-insight.svg'),
    logoImageAlt: 'ota insight',
  },
  parkhub: {
    logoImage: require('@/assets/images/logos/logo-parkhub.png'),
    logoImageAlt: 'Parkhub',
  },
  parkmobile: {
    logoImage: require('@/assets/images/logos/logo-park-mobile.svg'),
    logoImageAlt: 'Park Mobile',
  },
  rpg: {
    logoImage: require('@/assets/images/logos/logo-room-price-genie.svg'),
    logoImageAlt: 'Room Price Genie',
    showDenseCustomerCard: true,
  },
  spothero: {
    logoImage: require('@/assets/images/logos/logo-spothero.svg'),
    logoImageAlt: 'Spot Hero',
  },
  str: {
    logoImage: require('@/assets/images/logos/logo-str.svg'),
    logoImageAlt: 'STR Inc',
    showDenseCustomerCard: true,
  },
  uber: {
    logoImage: require('@/assets/images/logos/logo-uber.svg'),
    logoImageAlt: 'Uber',
  },
  walgreens: {
    logoImage: require('@/assets/images/logos/logo-walgreens.svg'),
    logoImageAlt: 'Walgreens',
  },
  zartico: {
    logoImage: require('@/assets/images/logos/logo-zartico.svg'),
    logoImageAlt: 'Zartico',
  },
}
