import * as R from 'ramda'
import PublicApiPlacesPlace from './place'

function PublicApiPlacesSearchResult (data) {
  let places = []

  if (R.has('results', data)) {
    places = R.map(place => {
      return new PublicApiPlacesPlace(place)
    }, data.results)
  }

  const result = {
    count: R.has('count', data) ? data.count : 0,
    places
  }

  return result
}

export default PublicApiPlacesSearchResult
