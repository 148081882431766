<template>
  <div :class="bem('')">
    <slot />
  </div>
</template>
<script>
import './form-row.scss'

export default {
  name: 'FormRow'
}
</script>
