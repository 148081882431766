import 'whatwg-fetch' // polyfill for older browsers
import queryString from 'query-string'
import config from '@/app/config'
import * as R from 'ramda'

const pause = (duration) => new Promise(resolve => setTimeout(resolve, duration))

const fetchRetry = (url, fetchConfig, delay = 100, remainingRetries = 3) => {
  return fetch(url, fetchConfig).then(async response => {
    // Only retry GET requests when it's a 5xx error
    if (remainingRetries > 0 && fetchConfig.method === 'get' && (response.status >= 500 && response.status <= 599)) {
      console.error(`Request failed, about to retry... (remaining retries: ${remainingRetries})`, response)

      delay = delay * 2.5
      await pause(delay)

      return fetchRetry(url, fetchConfig, delay, remainingRetries - 1)
    }

    return response
  }).catch(async error => {
    if (remainingRetries > 0 && fetchConfig.method === 'get') {
      console.error(`Request failed, about to retry... (remaining retries: ${remainingRetries})`, error)

      delay = delay * 2.5
      await pause(delay)

      return fetchRetry(url, fetchConfig, delay, remainingRetries - 1)
    } else {
      throw error
    }
  })
}

const client = {
  bearerToken: null,
  setBearerToken (bearerToken) {
    this.bearerToken = bearerToken
  },
  request ({ uri, params = {}, method = 'get', data = null, bearerToken = true }) {
    let url = config.PHQ_PUBLIC_API_ENDPOINT + uri

    if (!R.isEmpty(params)) {
      url += '?' + queryString.stringify(params)
    }

    const conf = {
      method,
      headers: {
        Accept: 'application/json',
        'X-User-Agent': `${config.USER_AGENT}/${config.APP_VERSION}`
      }
    }

    if (bearerToken && this.bearerToken !== null) {
      conf.headers.Authorization = `Bearer ${this.bearerToken}`
    }
    if (data !== null) {
      conf.headers['Content-type'] = 'application/json'
      conf.body = JSON.stringify(data)
    }

    return new Promise((resolve, reject) => {
      fetchRetry(url, conf).then(response => {
        if (response.ok) {
          // 204 = no content
          if (response.status !== 204) {
            return response.json()
          } else {
            return response.text()
          }
        } else {
          // Handle error
          throw new Error(response.status)
        }
      }).then(result => {
        if (result && R.has('error', result)) {
          reject(result)
        }

        resolve(result)
      }).catch(error => {
        reject(error)
      })
    })
  }
}

export default client
