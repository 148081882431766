<template>
  <header :class="[bem(''), 'sides']">
    <div :class="[bem('wrap'), noWrap ? '' : wrap]">
      <a href="https://predicthq.com" :class="bem('logo')">
        <img :src="require('@/assets/images/logo.svg')" alt="Homepage" />
      </a>
    </div>
  </header>
</template>

<script>
import './app-header.scss'

export default {
  name: 'AppHeader',
  props: {
    variation: {
      type: String,
      default: 'wide',
      validator: function (value) {
        return ['narrow', 'medium', 'wide'].indexOf(value) !== -1
      },
    },
    noWrap: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    wrap() {
      switch (this.variation) {
        case 'wide':
          return 'wrap'
        case 'medium':
          return 'wrap-588'
        case 'narrow':
          return 'wrap-384'
        default:
          return 'wrap'
      }
    },
  },
}
</script>
