<template>
  <div :class="bem('', { 'is-new-design': isNewDesign })">
    <AppHeader :noWrap="isNewDesign" />

    <div :class="[bem('main'), 'sides v-space-mt-5']">
      <div :class="isNewDesign ? '' : 'wrap'">
        <div :class="bem('row')">
          <div :class="[bem('body'), 'col-m-12 col-800-7 col-l-8 v-space-mb-5']">
            <div :class="[bem('body-content')]">
              <slot />
            </div>
          </div>

          <div :class="[bem('side'), 'col-m-12 col-800-5 col-l-5']">
            <div :class="bem('bg')">
              <img
                v-if="backgroundImage"
                :class="bem('bg-image')"
                :src="require(`@/assets/images/campaigns/${backgroundImage}`)"
                alt=""
              />
            </div>
            <div :class="bem('side-wrapper')">
              <div v-if="isNewDesign" :class="bem('new-design-content')">
                <AppTypography class="text-center" variant="xl">
                  <span class="c-brand-pink">Which events</span> impact your business most?
                </AppTypography>
                <img
                  :class="[bem('image'), 'v-space-mt-2 v-space-mb-3']"
                  :src="require('@/assets/images/illustration-categories.svg')"
                  alt=""
                  height="124"
                  width="528"
                />
                <div class="v-space-mt-8">
                  <AppTypography class="text-center" variant="xl">
                    <span class="c-brand-pink">Improve operations</span> and
                    <span class="c-brand-pink">increase revenue</span> with event driven predictions
                  </AppTypography>
                  <img
                    :class="[bem('image'), 'v-space-mt-2']"
                    :src="require('@/assets/images/illustration-charts.svg')"
                    alt=""
                    height="264"
                    width="528"
                  />
                </div>
              </div>
              <template v-else>
                <div v-if="title" :class="bem('side-content')">
                  <AppTypography variant="lg"><span v-html="title"></span></AppTypography>
                  <div v-if="paragraph" class="v-space-mt-3">
                    <AppTypography><span v-html="paragraph"></span></AppTypography>
                  </div>
                  <AppList v-if="list" class="v-space-mt-3">
                    <ListItem v-for="(item, i) in list" :key="`list-item-${i}`" check="green">{{ item }}</ListItem>
                  </AppList>
                </div>
                <div v-else :class="bem('side-quote')">
                  <img :class="bem('side-logo')" :src="randomQuote.logo" alt="" />
                  <AppQuote :author="randomQuote.author" :content="randomQuote.quote" size="tiny" />
                </div>
                <SignupFeaturedCustomers
                  v-if="featuredCustomers.length"
                  :featuredCustomers="featuredCustomers"
                  class="v-space-mt-8"
                  label="Trusted By"
                />
                <img
                  v-if="image"
                  :class="[bem('image'), 'v-space-mt-5 v-space-mb-5']"
                  :src="require(`@/assets/images/campaigns/${image}`)"
                  alt=""
                />
              </template>
            </div>
            <div v-if="!backgroundImage" :class="[bem('side-platform'), 'bg-platform-bars-pink']" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { AppList, AppHeader, AppQuote, ListItem, SignupFeaturedCustomers } from '@/components'
import { computed } from 'vue'
import AppTypography from '@predicthq/vue3.components.typography'
import customerLogos from '@/data/customer-logos'
import useExperiment from '@/use/experiment'

defineOptions({
  name: 'SideLayout',
})

const props = defineProps({
  title: String,
  paragraph: String,
  list: Array,
  logos: {
    type: Array,
    default: () => ['accor-hotels', 'blue-yonder', 'dominos', 'uber', 'walgreens', 'zartico'],
  },
  image: String,
  backgroundImage: String,
})

const experiment = useExperiment('phq_1169')

const quotes = [
  {
    quote:
      'PredictHQ has alleviated the burden of spending hundreds, if not thousands of hours on sourcing events and trying to make sense out of them. Even outside of the product team, we have team members ranging from marketing to sales who have all really benefited from the data.',
    author: {
      name: 'Kat Bykova',
      image: require('@/assets/images/authors/kat-bykova.png'),
      position: 'Product Manager',
      company: 'Launchtrip',
    },
    logo: require('@/assets/images/logos/logo-launchtrip.png'),
  },
  {
    quote:
      'The volatility of events was causing our previously automated event data streams to break, and we wanted a partner we could rely on.',
    author: {
      name: 'Kristof Elst',
      image: require('@/assets/images/authors/kristof-elst.png'),
      position: 'CTO',
      company: 'OTA Insight',
    },
    logo: require('@/assets/images/logos/logo-ota-insight.svg'),
  },
  {
    quote:
      'The beauty of machine learning is that it can detect the changes and differences across all restaurant locations and adapt, but you need to ensure the data into your models is clean, accurate, and reliable.',
    author: {
      name: 'Brian Bemiller',
      image: require('@/assets/images/authors/brian-bemiller.png'),
      position: 'Product Leader',
      company: 'Lineup',
    },
    logo: require('@/assets/images/logos/logo-lineup.png'),
  },
  {
    quote:
      "Don't underestimate how much effort it takes to work with event data... Being able to rely on a company whose sole purpose is to remove the ambiguity of event data has been game-changing for us.",
    author: {
      name: 'Thomas Joseph',
      image: require('@/assets/images/authors/thomas-joseph.jpg'),
      position: 'Head of Data Science',
      company: 'Legion',
    },
    logo: require('@/assets/images/logos/logo-legion.svg'),
  },
]

const featuredCustomers = computed(() => {
  return props.logos.map((customer) => customerLogos[customer])
})

const isNewDesign = computed(() => experiment.variant.value === '1')

const randomQuote = computed(() => {
  return quotes[Math.floor(Math.random() * quotes.length)]
})
</script>

<style lang="scss" scoped src="./side-layout.scss" />
