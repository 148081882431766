import freeEmailDomains from '@/data/free-email-domains.json'

// Messages use vee-validate, for more info on formatting check their docs:
// https://vee-validate.logaretm.com/v3/guide/basics.html#messages
export const rules = {
  business_email(v) {
    if (v?.trim().length > 0) {
      const emailDomain = v.split('@')[1]
      return !freeEmailDomains.includes(emailDomain)
    }
    return false
  },
  has_uppercase(v) {
    return /[A-Z]/.test(v)
  },
  has_number(v) {
    return /[0-9]/.test(v)
  },
  password(v) {
    return /^(?=.*[A-Z])(?=.*\d)[A-Za-z\d\W\S_]{8,}$/g.test(v) && !v.includes(' ')
  },
}

export const messages = {
  email: '{field} must be a valid email address',
  required: '{field} is required',
  min: '{field} must have at least 0:{length} characters',
  min_value: '{field} must be at least 0:{min}',
  max_value: '{field} may not be greater than 0:{max}',
  confirmed: '{field} does not match 0:{target}',
  business_email: 'You are required to enter a business email. Please try again.',
  has_uppercase: `{field} must have at least one uppercase letter`,
  has_number: `{field} must have at least one number`,
  password: '{field} does not match requirements',
}
