import Tooltip from 'tooltip.js'

function getTooltipFromBinding(el, binding) {
  const options = {
    title: binding.value,
    template: '<div class="tooltip" role="tooltip"><div class="tooltip-inner"></div></div>',
    delay: {
      show: 500,
      hide: 0,
    },
    popperOptions: {
      modifiers: {
        shift: {
          fn: (data) => {
            if (data.placement === 'top') {
              data.offsets.popper.top = data.offsets.popper.top - 5
            } else if (data.placement === 'bottom') {
              data.offsets.popper.top = data.offsets.popper.top + 5
            } else if (data.placement === 'right') {
              data.offsets.popper.left = data.offsets.popper.left + 5
            } else if (data.placement === 'left') {
              data.offsets.popper.left = data.offsets.popper.left - 5
            }

            return data
          },
        },
      },
    },
  }

  if (binding.modifiers) {
    if (binding.modifiers.left) {
      options.placement = 'left'
    } else if (binding.modifiers.right) {
      options.placement = 'right'
    } else if (binding.modifiers.top) {
      options.placement = 'top'
    } else if (binding.modifiers.bottom) {
      options.placement = 'bottom'
    }
  }

  return new Tooltip(el, options)
}

export default {
  mounted(el, binding) {
    if (binding.value) {
      el.tooltip = getTooltipFromBinding(el, binding)
    }
  },
  updated(el, binding) {
    if (el.tooltip && binding.value !== binding.oldValue) {
      el.tooltip.dispose()
      if (binding.value) {
        el.tooltip = getTooltipFromBinding(el, binding)
      }
    }
  },
  unmounted(el) {
    if (el.tooltip) {
      el.tooltip.dispose()
    }
  },
}
