import { inject } from 'vue'

const key = Symbol('systemMessages')

export const useSystemMessages = () => inject(key)

export default {
  install(app) {
    const systemMessages = {
      show(text, options = {}) {
        // The system-message component listens for these events
        app.config.globalProperties.emitter.emit('message', {
          text,
          options,
        })
      },
    }
    app.config.globalProperties.$messages = systemMessages
    app.provide(key, systemMessages)
  },
}
