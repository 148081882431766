import worldCountries from 'world-countries'
import * as R from 'ramda'

export function getCountry ({ alpha2 = '', alpha3 = '' } = {}) {
  const countryDetailsArray = R.filter((country) => {
    return country.alpha2 === alpha2 || country.alpha3 === alpha3
  }, getAllCountries())

  if (countryDetailsArray.length > 0) {
    const countryDetails = countryDetailsArray[0]

    return countryDetails
  }
}

export function getAllCountries () {
  return R.map(country => {
    return {
      alpha2: country.cca2,
      alpha3: country.cca3,
      name: {
        common: country.name.common,
        official: country.name.official
      },
      languages: country.languages,
      demonym: country.demonym,
      region: country.region,
      subRegion: country.subRegion,
      capital: country.capital,
      location: {
        lat: country.latlng[0],
        lng: country.latlng[1]
      },
      flag: country.flag
    }
  }, worldCountries)
}
