<template>
  <div :class="bem('', { column })">
    <slot />
  </div>
</template>
<script>
import './app-button-group.scss'

export default {
  name: 'AppButtonGroup',
  props: {
    column: Boolean,
  },
}
</script>
