import client from './util/client'

export default {
  verify (inviteId, inviteToken) {
    return client.request({ uri: `/signup/verify-invite/${inviteId}/${inviteToken}`, bearerToken: false })
  },
  accept (orgId, inviteId, data) {
    return client.request({ uri: `/signup/invites/${orgId}/${inviteId}/accept`, method: 'post', data })
  },
  reject (orgId, inviteId, data) {
    return client.request({ uri: `/signup/invites/${orgId}/${inviteId}/reject`, method: 'post', data })
  }
}
