/**
 * Generally we shouldn't be doing any DOM interaction because Vue handles it all
 * for us. But we can't use Vue for <body> and <title> (etc) manipulation.
 */
export default {
  setPageTitle (text) {
    document.title = (text.length > 0) ? text + ' - PredictHQ' : 'PredictHQ'
  },
  setBodyClasses (classes) {
    document.body.className = classes
  }
}
