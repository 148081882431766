import * as R from 'ramda'
import moment from 'moment-timezone'

function VerifiedInvite (inviteId, data) {
  let inviteDate = null
  let expireDate = null

  if (R.has('invite_dt', data) && data.invite_dt !== null) {
    const date = R.has('invite_dt', data) ? moment.utc(data.invite_dt) : moment()

    inviteDate = {
      date,
      relative: date.fromNow()
    }
  }
  if (R.has('expire_dt', data) && data.expire_dt !== null) {
    const date = R.has('expire_dt', data) ? moment.utc(data.expire_dt) : moment()

    expireDate = {
      date,
      relative: date.fromNow()
    }
  }

  return {
    inviteId,
    email: R.has('email', data) ? data.email : null,
    firstName: R.has('first_name', data) ? data.first_name : null,
    lastName: R.has('last_name', data) ? data.last_name : null,
    get name () {
      const parts = []

      if (this.firstName) {
        parts.push(this.firstName)
      }
      if (this.lastName) {
        parts.push(this.lastName)
      }

      return parts.join(' ')
    },
    get initials () {
      const initials = []

      if (this.firstName) {
        initials.push(this.firstName.slice(0, 1).toUpperCase())
      }
      if (this.lastName) {
        initials.push(this.lastName.slice(0, 1).toUpperCase())
      }

      return initials.join('')
    },
    orgId: R.has('org_id', data) ? data.org_id : null,
    orgName: R.has('org_name', data) ? data.org_name : null,
    status: R.has('status', data) ? data.status : null,
    existingUser: R.has('existing_user', data) ? data.existing_user : null,
    inviterEmail: R.has('inviter_email', data) ? data.inviter_email : null,
    inviteDate,
    expireDate
  }
}

export default VerifiedInvite
