import * as R from 'ramda'
import jwt_decode from 'jwt-decode'
import auth from '@/app/api/auth/auth'

// Load initial state from sessionStorage
const signupSessionStorageState = JSON.parse(window.sessionStorage.getItem('phq.signup') || '{}')

const state = {
  token: null,
  password: null // Used to login on behalf of the user after signup
}

if (R.has('token', signupSessionStorageState)) {
  state.token = signupSessionStorageState.token
}

export default {
  namespaced: true,
  state,
  actions: {
    signup ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        auth.signup(payload).then(result => {
          commit('setToken', result.token)

          resolve(result)
        }).catch(error => {
          reject(error)
        })
      })
    }
  },
  getters: {
    tokenDetails: state => {
      if (state.token !== null && state.token.length > 0) {
        // Pull out some token details
        const jwt = jwt_decode(state.token)

        if (R.has('det', jwt)) {
          return jwt.det
        }
      }

      return null
    }
  },
  mutations: {
    setToken (state, token) {
      state.token = token
    },
    removeToken (state) {
      state.token = null
    },
    setPassword (state, password) {
      state.password = password
    },
    removePassword (state) {
      state.password = null
    },
  }
}
