
export default function createLocalStoragePlugin (storageKey, tester, filter) {
  // Clear localStorage when running tests
  if (navigator.userAgent.indexOf('PhantomJS') > -1) {
    // window.localStorage.clear()
    window.localStorage.removeItem(storageKey)
  }

  // Listen for state changes from other open tabs
  // window.addEventListener('storage', function (e) {
  // state = JSON.parse(e.newValue)

  // console.log(state)

  // todo - look at updating state so tabs are kept up-to-date
  // important not to update the router state though as the user should
  // be allowed to view different content on different tabs
  // })

  return store => {
    store.subscribe((mutation, state) => {
      let doWrite = true

      if (typeof tester !== 'undefined' && tester(mutation, state, store) !== true) {
        doWrite = false
      }

      if (doWrite) {
        if (filter !== 'undefined') {
          // Pass state through the filter
          window.localStorage.setItem(storageKey, JSON.stringify(filter(state)))
        } else {
          // Write full state
          window.localStorage.setItem(storageKey, JSON.stringify(state))
        }
      }
    })
  }
}
