<template>
  <fieldset
    :class="[bem('', validate ? modifier : ''), q.length === 0 ? bem('', 'empty') : bem('', 'full')]"
    @keydown.esc="showSuggestions = false"
  >
    <input
      :id="name"
      v-model="q"
      type="text"
      :class="bem('field')"
      :name="name"
      autocomplete="off"
      @input="onInput"
      @focus="showSuggestions = true"
      @click="showSuggestions = true"
    />
    <label v-if="label" :for="name" :class="bem('label')">{{ label }}</label>
    <AppIcon v-if="selectedPlace" :class="bem('icon')" :icon="`form/${modifier}`" :size="16" />
    <span v-if="loading" :class="bem('loader')">
      <span :class="bem('loader-dot')"></span>
      <span :class="bem('loader-dot')"></span>
      <span :class="bem('loader-dot')"></span>
    </span>
    <div v-if="showSuggestions && searchResult && searchResult.places.length > 0" :class="bem('suggestion-box')">
      <ul>
        <li v-for="place in searchResult.places" :key="place.placeId" :class="bem('place')">
          <button :class="bem('suggestion')" type="button" @click="select(place)">
            <strong>{{ place.name }}</strong> &ndash; <template v-if="place.region">{{ place.region }}</template>
            <template v-if="place.countryDetails">, {{ place.countryDetails.name.common }}</template>
          </button>
        </li>
      </ul>
    </div>
  </fieldset>
</template>

<script>
import './app-input-places.scss'

import AppIcon from '@predicthq/vue3.components.icon'
import * as R from 'ramda'
import { debounce } from 'lodash'

export default {
  name: 'AppInputPlaces',
  components: { AppIcon },
  props: {
    modelValue: {
      type: String,
      default: '',
      required: false,
    },
    name: {
      type: String,
      default: '',
      required: false,
    },
    label: {
      type: String,
    },
    modifier: {
      type: String,
      default: '',
      validator: function (value) {
        // The value must match one of these strings
        return ['', 'success', 'error'].indexOf(value) !== -1
      },
    },
    showError: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      showSuggestions: true,
      q: '',
      loading: false,
      searchResult: null,
      activeIcon: '',
      activeState: '',
      validate: false,
    }
  },
  computed: {
    selectedPlace() {
      return R.has(this.modelValue, this.$store.state.placePicker.places)
        ? this.$store.state.placePicker.places[this.modelValue]
        : null
    },
  },
  watch: {
    modelValue() {
      this.prepare()
    },
    q(newValue) {
      if (!newValue || newValue.length === 0) {
        this.$emit('update:modelValue', '')
      }
    },
    selectedPlace: {
      handler(newValue) {
        if (newValue) {
          this.q = newValue.countryDetails
            ? `${newValue.name} ${newValue.countryDetails.name.common}`
            : `${newValue.name}`
        }
      },
      immediate: true,
    },
    showError: {
      handler(val) {
        if (this.required) {
          this.validate = val
        }
      },
      immediate: true,
    },
  },
  mounted() {
    window.addEventListener('click', (e) => {
      // close dropdown when clicked outside
      if (!this.$el.contains(e.target)) {
        this.showSuggestions = false
      }
    })
  },
  created() {
    this.prepare()
  },
  methods: {
    onInput() {
      this.showSuggestions = true
      this.search()
    },
    resetField() {
      this.q = ''
    },
    prepare() {
      if (this.modelValue && this.modelValue.length > 0 && !this.selectedPlace) {
        this.loading = true

        // Lookup selected place
        this.$store
          .dispatch('placePicker/get', this.modelValue)
          .then(() => {
            this.loading = false
          })
          .catch((error) => {
            console.error(error)
            this.loading = false

            this.$messages.show('Error fetching place', {
              type: 'warning',
            })
          })
      }
    },
    select(place) {
      this.q = place.countryDetails ? `${place.name} ${place.countryDetails.name.common}` : `${place.name}`
      this.showSuggestions = false
      this.$emit('update:modelValue', place.placeId)
    },
    search: debounce(function () {
      if (this.q.length > 0) {
        this.loading = true

        this.$store
          .dispatch('placePicker/search', {
            q: this.q,
            limit: 5,
          })
          .then((searchResult) => {
            this.searchResult = searchResult
            this.loading = false
          })
          .catch((error) => {
            console.error(error)
            this.searchResult = null

            /*
            TODO not using ApiError because auth-backend incorrectly shows html
            error page instead of returning json.
            if (error instanceof ApiError) {
              this.$messages.show(error.message, {
                type: 'warning'
              })
            } else {
              this.$messages.show('Error fetching industries', {
                type: 'warning'
              })
            }
            */
            this.$messages.show('Error searching places', {
              type: 'warning',
            })
          })
      } else {
        this.searchResult = null
      }
    }, 300),
  },
}
</script>
