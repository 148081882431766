<template>
  <CenteredLayout :textCentered="true">
    <AppSpinner center />
  </CenteredLayout>
</template>

<script>
import auth from '@/app/api/auth/auth'
import { AppSpinner } from '@/components'
import { CenteredLayout } from '@/layouts'

export default {
  name: 'LogoutView',
  components: {
    AppSpinner,
    CenteredLayout,
  },
  beforeMount() {
    // Don't wait for response from server logout
    auth.logout().then(() => {
      this.$store.commit('auth/logout')
      this.$router.push('/')
    })
  },
}
</script>
