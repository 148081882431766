<template>
  <ul :class="bem('')">
    <slot></slot>
  </ul>
</template>
<script>
import './app-list.scss'

export default {
  name: 'AppList',
  computed: {},
  methods: {}
}
</script>
