import { createStore } from 'vuex'
import auth from './modules/auth'
import invite from './modules/invite'
import placePicker from './modules/place-picker'
import route from './modules/route'
import signup from './modules/signup'
import createLocalStoragePlugin from './plugins/local-storage'
import createSessionStoragePlugin from './plugins/session-storage'

const debug = process.env.NODE_ENV !== 'production'

const authLocalStoragePlugin = createLocalStoragePlugin(
  'phq.auth',
  (mutation, state, store) => {
    if (store.getters['auth/authenticated'] !== true) {
      window.localStorage.removeItem('phq.auth')
    }

    // Only persist to localStorage when user is logged in
    return mutation.type.startsWith('auth/') && store.getters['auth/authenticated'] === true
  },
  (state) => {
    // Only store auth-related state
    return state.auth
  },
)

const signupSessionStoragePlugin = createSessionStoragePlugin(
  'phq.signup',
  (mutation) => {
    return mutation.type.startsWith('signup/')
  },
  (state) => {
    // Only store signup-related state
    return state.signup
  },
)

const store = createStore({
  modules: {
    auth,
    invite,
    placePicker,
    route,
    signup,
  },
  plugins: [authLocalStoragePlugin, signupSessionStoragePlugin],
  strict: debug,
})

export default store
