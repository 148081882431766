<template>
  <div :class="[bem('')]">
    <div class="wrap-588">
      <div :class="bem('text', size)">“{{ content }}”</div>
      <SignupAuthor v-bind="author" :class="bem('author')" />
    </div>
  </div>
</template>
<script>
import { SignupAuthor } from '@/components'

export default {
  name: 'AppQuote',
  components: {
    SignupAuthor,
  },
  props: {
    content: String,
    author: Object,
    size: {
      type: String,
      default: 'normal',
      validator: (value) => {
        return ['normal', 'small', 'tiny'].includes(value)
      },
    },
  },
}
</script>

<style lang="scss" scoped src="./app-quote.scss" />
