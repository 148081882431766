<template>
  <CenteredLayout :textCentered="true">
    <template v-if="!loading">
      <FormHero title="Sign in to PredictHQ">
        <p class="text-p-sm">
          By signing up you agree to PredictHQ's
          <a href="https://www.predicthq.com/legal/terms" target="_blank"> Terms of Service </a>
          &amp;
          <a href="https://www.predicthq.com/legal/privacy" target="_blank"> Privacy </a>
        </p>
      </FormHero>

      <AppButton @click="redirectToSignin"> Sign in </AppButton>
    </template>
    <template v-else>
      <AppSpinner center />
    </template>
  </CenteredLayout>
</template>

<script>
import * as R from 'ramda'
import config from '@/app/config'
import auth from '@/app/api/auth/auth'
import users from '@/app/api/auth/users'
import AuthRefresh from '@/app/auth/refresh'

import { FormHero, AppButton, AppSpinner } from '@/components'

import { CenteredLayout } from '@/layouts'

export default {
  name: 'LoginView',
  components: {
    AppSpinner,
    FormHero,
    AppButton,
    CenteredLayout,
  },
  data() {
    return {
      loading: false,
    }
  },
  created() {
    if (R.has('code', this.$route.query) && R.has('state', this.$route.query)) {
      this.loading = true
      const state = window.localStorage.getItem('phq.auth.state')
      window.localStorage.removeItem('phq.auth.state')
      // Make sure the "state" matches
      if (this.$route.query.state === state) {
        this.exchangeAuthCodeForToken(this.$route.query.code)
          .then((data) => {
            this.$store.commit('auth/setAuthTokenDetails', data)
            // Fetch user and membership to get possible scopes
            const promises = []
            promises.push(users.get(this.$store.getters['auth/user'].userId))
            promises.push(
              users.memberships(this.$store.getters['auth/user'].userId, {
                org_id: this.$store.getters['auth/org'].orgId,
              }),
            )
            Promise.all(promises)
              .then(async (results) => {
                const [user, membership] = results
                const userScopes = user.scopes
                const membershipScopes = membership.count === 1 ? membership.memberships[0].scopes : []
                // Merge membership and user scopes
                const availableScopes = R.uniq([...userScopes, ...membershipScopes])
                // We request as much scope as possible (as needed by this app)
                const requestScopes = R.filter(
                  (scope) => R.includes(scope, availableScopes),
                  config.AUTH_POSSIBLE_SCOPES,
                )
                // Now refresh the token, requesting additional scopes
                const exchangeAuthCodeForToken = async (code) => {
                  return new Promise((resolve, reject) => {
                    auth
                      .exchangeCodeForToken(
                        `grant_type=authorization_code&client_id=${
                          config.AUTH_CLIENT_ID
                        }&redirect_uri=${encodeURIComponent(config.APP_URL + '/silent.html')}&code=${code}`,
                      )
                      .then((data) => {
                        resolve(data)
                      })
                      .catch((error) => {
                        reject(error)
                      })
                  })
                }
                const refreshAuth = async () => {
                  return new Promise((resolve, reject) => {
                    const org = this.$store.getters['auth/org']
                    const orgId = org ? org.orgId : null
                    AuthRefresh.refresh({ orgId })
                      .then((code) => {
                        exchangeAuthCodeForToken(code)
                          .then((data) => {
                            this.$store.commit('auth/setAuthTokenDetails', data)
                            resolve()
                          })
                          .catch((error) => {
                            reject(error)
                          })
                      })
                      .catch((error) => {
                        reject(error)
                      })
                  })
                }
                AuthRefresh.setScopes(requestScopes)
                await refreshAuth()
                this.loading = false
                // Figure out which page the user was trying to view before logging in
                const continuePath = window.localStorage.getItem('phq.auth.continue')
                window.localStorage.removeItem('phq.auth.continue')
                if (continuePath !== null) {
                  this.$router.push(continuePath)
                } else {
                  this.$router.push('/')
                }
              })
              .catch((error) => {
                this.loading = false
                this.$store.commit('auth/logout')
                this.$messages.show(error.message, { type: 'warning' })
                this.$router.push('/')
              })
          })
          .catch((error) => {
            this.loading = false
            this.$messages.show(error.message, { type: 'warning' })
            this.$router.push('/')
          })
      } else {
        this.loading = false
        this.$router.push('/')
      }
    } else {
      this.loading = true
      if (R.has('continue', this.$route.query)) {
        window.localStorage.setItem('phq.auth.continue', this.$route.query.continue)
      }
      const orgId = R.has('org', this.$route.query) ? this.$route.query.org : null
      this.redirectToSignin({ orgId })
    }
  },
  methods: {
    redirectToSignin({ orgId = null } = {}) {
      let state = window.localStorage.getItem('phq.auth.state')

      if (!state) {
        // No existing state so generate a new state
        state = Math.random().toString(36).slice(2)
        window.localStorage.setItem('phq.auth.state', state)
      }

      const params = {
        response_type: 'code',
        client_id: config.AUTH_CLIENT_ID,
        scope: encodeURIComponent(config.AUTH_INITIAL_SCOPE.join(' ')).replace(/%20/g, '+'),
        redirect_uri: encodeURIComponent(config.APP_URL + '/auth/login'),
        state: state,
      }

      if (orgId) {
        params.org_id = orgId
      }

      // Convert object to uri string
      const uri = R.compose(R.join('&'), R.map(R.join('=')), R.toPairs)(params)

      window.location = `${config.AUTH_ENDPOINT}/authorize?${uri}`
    },
    async exchangeAuthCodeForToken(code) {
      return new Promise((resolve, reject) => {
        auth
          .exchangeCodeForToken(
            `grant_type=authorization_code&client_id=${config.AUTH_CLIENT_ID}&redirect_uri=${encodeURIComponent(
              config.APP_URL + '/auth/login',
            )}&code=${code}`,
          )
          .then((data) => {
            resolve(data)
          })
          .catch((error) => {
            console.error(error)
            reject(error)
          })
      })
    },
  },
}
</script>
