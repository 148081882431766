<template>
  <CenteredLayout :textCentered="true">
    <FormHero :title="title">
      <p>Your new account has been created and you are ready to log in.</p>

      <p v-if="!tokenDetails.provider && tokenDetails.email">
        You have signed up with
        <strong> {{ tokenDetails.email }} </strong>.
      </p>

      <p v-else> You will now be taken to the login screen to login with your new account. </p>
    </FormHero>

    <form
      v-if="!tokenDetails.provider && tokenDetails.email && password"
      :action="`${config.AUTH_ENDPOINT}/login`"
      method="post"
    >
      <input type="hidden" name="email" :value="tokenDetails.email" />
      <input type="hidden" name="password" :value="password" />
      <input type="hidden" name="continue" :value="loginUrl" />
      <AppButton type="submit">Log in</AppButton>
    </form>

    <AppButton v-else :url="loginUrl">Log in</AppButton>
  </CenteredLayout>
</template>

<script>
import config from '@/app/config'
import { FormHero, AppButton } from '@/components'
import { CenteredLayout } from '@/layouts'
import { getContinueUrl } from '@/utils/continueUrl'

export default {
  name: 'CompleteView',
  components: {
    FormHero,
    AppButton,
    CenteredLayout,
  },
  data() {
    return {
      tokenDetails: {},
      password: null,
    }
  },
  computed: {
    config() {
      return config
    },
    token() {
      return this.$store.state.signup.token
    },
    title() {
      const invite = this.$store.state.invite.verifiedInvite
      if (invite) {
        return 'You’ve signed up'
      } else {
        return 'You’ve signed up for a 14 day trial'
      }
    },
    loginUrl() {
      return `${config.APP_CONTROL_CENTER_URL}${getContinueUrl()}`
    },
  },
  created() {
    // redirect to home if token doesn't exist
    if (!this.token) {
      this.$router.push('/')
    } else {
      this.updateTokenDetails()
      // Remove signup token
      this.$store.commit('signup/removeToken')

      // Grab password and remove it from the store
      this.password = this.$store.state.signup.password
      this.$store.commit('signup/removePassword')
    }
  },
  methods: {
    updateTokenDetails() {
      // stores a copy of tokenDetails locally to populate data on this page
      this.tokenDetails = { ...this.$store.getters['signup/tokenDetails'] }
    },
  },
}
</script>
