<template>
  <CenteredLayout :textCentered="true">
    <AppSpinner center />
  </CenteredLayout>
</template>

<script>
import * as R from 'ramda'
import { AppSpinner } from '@/components'
import { CenteredLayout } from '@/layouts'

export default {
  name: 'OpenidView',
  components: {
    AppSpinner,
    CenteredLayout,
  },
  created() {
    this.prepare()
  },
  methods: {
    prepare() {
      if (R.has('token', this.$route.query)) {
        this.$store.commit('signup/setToken', this.$route.query.token)
        this.$router.push('/account')
      } else {
        this.$router.push('/')
      }
    },
  },
}
</script>
